import React, { Component } from 'react';
import { connect } from "react-redux";
import SalesProfitChart from './SalesProfitChart';
import ItemsListedChart from './ItemsListedChart';
import ItemsSoldChart from './ItemsSoldChart';
import TotalOrdersChart from './TotalOrdersChart';
import AvgSalePriceChart from './AvgSalePriceChart';
import './index.scss';
import { Card, CardBody } from 'reactstrap';
import dashboardActions from "../../../redux/main_dashboard/actions";

const {
  getMobileDashboardData,
} = dashboardActions;

class MobileDashboard extends Component {

  componentDidMount = () => {
    //this.props.getMobileDashboardData({action: "sold"})
    //this.props.getMobileDashboardData({action: "profit"})
    //this.props.getMobileDashboardData({action: "orders"})
    //this.props.getMobileDashboardData({action: "avg_price"})
    //this.props.getMobileDashboardData({action: "items"})
    this.props.getMobileDashboardData({action: "all"})
  }

  render() {
    const {
      mobile_dashboard_data,
      mobile_dashboard_data_working,
    } = this.props;

    console.log("mobile_dashboard_data", mobile_dashboard_data);

    return (
      <div className="mobile-content-container">
        <h1 className="mobile-content-title">Dashboard</h1>
        <div className="mobile-content-main">
          <Card className="mobile-chart-container mb-4">
            <CardBody>
              <h2 className="mobile-chart-title">Sales Profit <span className="text-muted">(Last 30 Days)</span></h2>
              <SalesProfitChart
                data={mobile_dashboard_data?.profit ? mobile_dashboard_data?.profit : []}
              />
            </CardBody>
          </Card>

          <Card className="mobile-chart-container mb-4">
            <CardBody>
              <h2 className="mobile-chart-title">Items Listed <span className="text-muted">(Last 7 Days)</span></h2>
              <ItemsListedChart
                data={mobile_dashboard_data?.items ? mobile_dashboard_data?.items : []}
              />
            </CardBody>
          </Card>

          <Card className="mobile-chart-container mb-4">
            <CardBody>
              <h2 className="mobile-chart-title">Items Sold <span className="text-muted">(Last 7 Days)</span></h2>
              <ItemsSoldChart
                data={mobile_dashboard_data?.sold ? mobile_dashboard_data?.sold : []}
              />
            </CardBody>
          </Card>

          <Card className="mobile-chart-container mb-4">
            <CardBody>
              <h2 className="mobile-chart-title">Total Orders <span className="text-muted">(Last 7 Days)</span></h2>
              <TotalOrdersChart
                data={mobile_dashboard_data?.orders ? mobile_dashboard_data?.orders : []}
              />
            </CardBody>
          </Card>

          <Card className="mobile-chart-container mb-4">
            <CardBody>
              <h2 className="mobile-chart-title">Average Sale Price <span className="text-muted">(Last 7 Days)</span></h2>
              <AvgSalePriceChart
                data={mobile_dashboard_data?.avg_price ? mobile_dashboard_data?.avg_price : []}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    userData: state.Auth.get("userData"),
    mobile_dashboard_data: state.Dashboard.get("mobile_dashboard_data"),
    mobile_dashboard_data_working: state.Dashboard.get("mobile_dashboard_data_working"),

  }),
  {
    getMobileDashboardData,
  }
)(MobileDashboard);
