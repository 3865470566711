import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, CardTitle, Popover, PopoverBody, Input, Label, FormGroup } from "reactstrap"
import BoxContentModal from "../../../../../shared/components/BoxContentModal"
import IconBox from "react-icons/lib/io/ios-box";
import IconEdit from "react-icons/lib/md/edit";
import fulfillmentActions from "../../../../../redux/fulfillment/actions";
import CreateNewBoxModal from "../../../../../shared/components/CreateNewBoxModal";
import { secureProtocolImgURL } from "../../../../../helpers/utility";
import { Badge } from "reactstrap";
const {
  fulfillmentBoxesLoadAll,
  fulfillmentBoxUpdate,
} = fulfillmentActions;

class InboundPackingInformation extends Component {
  state = {
    showCreateNewBoxModal: false,
    showBoxContentModal: false,
    box_packing_group_id: null,
    active_box: null,
    active_box_idx: null,
    hideCompletedItems: false,
  }

  componentDidMount = () => {
    this.props.fulfillmentBoxesLoadAll({ batch: this.props.batchMetadata.id })
  }

  calculateHeaderGroup = (data) => {
    const units = data?.items.reduce((accumulator, current) => accumulator + current.quantity, 0);
    return `${data.items.length} SKUs (${units} unit${units < 1 ? "" : "s"})`;
  }

  calculateBoxItemsCount = (data) => {
    const units = data?.items?.reduce((accumulator, current) => accumulator + current.quantity, 0);
    return units;
  }

  calculateRemainingQty = (data, key) => {
    return data?.items?.reduce((total, item) => {
      const boxedQty = this.props.boxes
        .filter(box => box.packing_group_id === key)
        .reduce((boxTotal, box) => {
          const matchingItem = box.items.find(boxItem => boxItem.msku === item.msku);
          return boxTotal + (matchingItem?.quantity || 0);
        }, 0);
      return total + (item.quantity - boxedQty);
    }, 0)
  }

  findImage = (item) => {
    if(item?.image_url){ return secureProtocolImgURL(item.image_url); }
    const { products } = this.props;
    const aitem = products.find(aitem => aitem.sku === item.msku);
    if(aitem?.imageUrl){ return secureProtocolImgURL(aitem?.imageUrl) };
    return `https://www.amazon.com/images/P/${item.asin}.01._MZZZZZZZ_.jpg`;
  }

  findTitle = (item) => {
    const { products } = this.props;
    const aitem = products.find(aitem => aitem.sku === item.msku);
    return aitem?.name;
  }

  calculateQtyInAllBoxes = (msku, packing_group_id) => {
    const { boxes } = this.props;
    var qty = 0;
    boxes?.forEach(box => {
      box?.items?.forEach(item => {
        if(item.msku === msku){ qty += item.quantity }
      })
    })
    return qty;
  }

  render() {
    const {
      batchMetadata,
      boxes,
      fulfillment_inbound_working,
    } = this.props

    if(!batchMetadata?.inbound_packing_groups_data){ return null; }

    console.log("batchMetadata:", batchMetadata);

    return (
      <div>
        <FormGroup check className="custom-check checkbox">
          <Label check>
            <Input
              type="checkbox"
              checked={this.state.hideCompletedItems}
              onChange={() => this.setState({hideCompletedItems: !this.state.hideCompletedItems})}
            /> <span>Hide completed items</span>
          </Label>
        </FormGroup>
        <div className="d-flex flex-column gap-4 mb-4">
          {Object.keys(batchMetadata?.inbound_packing_groups_data).map((key, idx) => {
            return (
              <Card key={key} className="card-packing-group mb-0">
                <CardHeader className="d-flex align-items-center justify-content-between gap-4 px-3 py-2">
                  <div className="d-flex align-items-center gap-2">
                    <CardTitle className="mb-0">Packing Group {`${idx + 1}`}</CardTitle>
                    <span>{this.calculateHeaderGroup(batchMetadata?.inbound_packing_groups_data[key])}</span>
                  </div>
                  <div className="text-muted text-capitalize">
                    <span>Remaining qty: </span>
                    {this.calculateRemainingQty(batchMetadata?.inbound_packing_groups_data[key], key)}
                  </div>
                </CardHeader>
                <CardBody className="px-3 py-0 d-flex">
                  <div className="d-flex flex-wrap gap-4 py-2" style={{ flexBasis: '50%' }}>
                    {batchMetadata?.inbound_packing_groups_data[key]?.items?.map(item => {
                      const remainingQty = item.quantity - this.calculateQtyInAllBoxes(item.msku, key);
                      if (this.state.hideCompletedItems && remainingQty <= 0) return null;
                      return (
                        <div key={item.asin}className="text-center">
                          <img
                            src={this.findImage(item)}
                            alt="" height={40} className="mb-1 product-thumbnail"
                          />
                          {/* TODO: provide title data */}
                          <span className="d-block text-truncate font-weight-semi-bold" style={{maxWidth: "150px"}} title={this.findTitle(item)}>
                            {this.findTitle(item)}
                          </span>
                          <span className="d-block">ASIN: {item.asin}</span>
                          <div className="d-flex justify-content-center">
                            <Badge color={item.quantity - this.calculateQtyInAllBoxes(item.msku, key) > 0 ? "warning" : "success"} className="px-2">
                              {this.calculateQtyInAllBoxes(item.msku, key)} of {item.quantity}
                            </Badge>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="d-flex align-items-center gap-3 flex-wrap border-left pl-3 py-2" style={{ flexBasis: '50%' }} >
                    {boxes.filter(item => item.packing_group_id === key).length === 0
                      ? <div className="text-muted">No box(es) for this group yet.</div>
                      : boxes.filter(item => item.packing_group_id === key).map((item, idx) => {
                          return (
                            <div
                              key={`box-packing-${idx}`}
                              className="mb-2 text-center border rounded p-2"
                              role="button"
                              onClick={() => this.setState({
                                showBoxContentModal: true,
                                active_box: item,
                                active_box_idx: idx,
                                box_packing_group_id: key,
                              })}
                            >
                              <IconBox size="30" />
                              <span className="d-block">Box {`${idx + 1}`} ({this.calculateBoxItemsCount(item)})</span>
                              <div className="d-flex align-items-center gap-2">
                                <span className="d-block">{item.weight_value || 0} {this.props?.internationalization_config?.weight_identifier}</span>
                                <IconEdit
                                  id={`weight-edit-${item.id}`}
                                  size={14}
                                  style={{cursor: 'pointer'}}
                                  onClick={e => {
                                    e.stopPropagation();
                                    this.setState({
                                      editingWeight: this.state.editingWeight === item.id ? null : item.id,
                                      weightInput: item.weight_value || 0
                                    });
                                  }}
                                  disabled={fulfillment_inbound_working}
                                />
                                <Popover
                                  placement="bottom"
                                  isOpen={this.state.editingWeight === item.id}
                                  target={`weight-edit-${item.id}`}
                                  toggle={() => this.setState({editingWeight: this.state.editingWeight === item.id ? null : item.id})}
                                >
                                  <PopoverBody>
                                    <div className="d-flex align-items-center gap-2">
                                      <Label>Est. Weight: </Label>
                                      <Input
                                        type="number"
                                        size="sm"
                                        min={0}
                                        style={{width: 80}}
                                        placeholder={this.props?.internationalization_config?.weight_identifier}
                                        value={this.state.weightInput || item.weight_value || 0}
                                        onChange={e => this.setState({weightInput: Number(e.target.value)})}
                                        disabled={fulfillment_inbound_working}
                                      />
                                      <Button
                                        color="primary"
                                        size="sm"
                                        onClick={() => {
                                          // update weight here
                                          this.props.fulfillmentBoxUpdate({
                                            id: item.id,
                                            batch_id: batchMetadata.id,
                                            weight_value: this.state.weightInput,
                                          });
                                          this.setState({editingWeight: null, weightInput: null});
                                        }}
                                        disabled={fulfillment_inbound_working || !this.state.weightInput}
                                      >
                                        Save
                                      </Button>
                                    </div>
                                  </PopoverBody>
                                </Popover>
                              </div>
                            </div>
                          )
                        })
                    }
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => this.setState({
                        showCreateNewBoxModal: true,
                        box_packing_group_id: key,
                        active_box: null,
                      })}
                    >Add Box</Button>
                  </div>
                </CardBody>
              </Card>
            )
          })}
        </div>
        {this.state.showCreateNewBoxModal &&
        <CreateNewBoxModal
          isOpen={true}
          toggle={() => this.setState({ showCreateNewBoxModal: false })}
          batchMetadata={batchMetadata}
          packing_group_id={this.state.box_packing_group_id}
          boxes={boxes}
        />
        }
        {this.state.showBoxContentModal &&
        <BoxContentModal
          isOpen={true}
          toggle={() => this.setState({
            showBoxContentModal: false,
            active_box: null,
          })}
          batchMetadata={batchMetadata}
          packing_group_id={this.state.box_packing_group_id}
          active_box={this.state.active_box}
          active_box_idx={this.state.active_box_idx}
          boxes={boxes}
        />
        }
      </div>
    )
  }
}

export default connect(
  state => ({
		userData: state.Auth.get('userData'),
    fulfillment_inbound_working: state.Fulfillment.get("fulfillment_inbound_working"),
    boxes: state.Fulfillment.get("boxes"),
    products: state.Batch.get("products"),
    internationalization_config: state.Auth.get("internationalization_config"),
  }),
  {
    fulfillmentBoxesLoadAll,
    fulfillmentBoxUpdate,
  }
)(InboundPackingInformation);
