import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalBody
} from 'reactstrap'
import PropTypes from 'prop-types'
import './style.css';

class DeleteDialog extends Component {
  render() {
    const { 
      close,
      isOpen,
      deleteItem,
      dialogText,
      confirmButtonColor,
      dialogTitle,
      cancelText,
      confirmText
    } = this.props;

    return (
      <Modal isOpen={isOpen} centered className="delete-dialog" toggle={close}>
        <ModalBody className="text-center">
          <div>
            <span className="icon icon-warning bg-danger"></span>
          </div>
          <h5 className="delete-dialog-title">{dialogTitle ? dialogTitle : "Are you sure?"}</h5>
          <p className="delete-dialog-description">{dialogText}</p>
          <div className="col-align-center gap-5 mt-4">
            <Button onClick={close}>
              {cancelText ? cancelText : "No, keep it"}
            </Button>
            <Button color={confirmButtonColor ? confirmButtonColor : "danger"} onClick={deleteItem}>
              {confirmText ? confirmText : "Yes, delete it"}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

DeleteDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  dialogText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
}

export default DeleteDialog;
