import React from "react";
import { connect } from "react-redux";
import { Badge, Button, Card, CardHeader, Label, Collapse, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import {
  digitСonversion,
  lbsToKg,
  momentDateTimeToLocalFormatConversion,
  checkIfEmployee,
  checkIfEmployeeAndAllowed,
} from "../../../../../helpers/utility";
import fulfillmentActions from "../../../../../redux/fulfillment/actions";
import Select from 'react-select';
import PackingGroupItemsModal from "../../../../../shared/components/PackingGroupItemsModal";
import PackGroupModal from "../../../../../shared/components/PackGroupModal";
import GetLabelsModal from "../../../../../shared/components/GetLabelsModal";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"
import ShipmentTrackingModal from "../../../../../shared/components/ShipmentTrackingModal";
import IconPrint from 'react-icons/lib/md/print';
//import PrintItemsLabel from "../../../../../shared/components/PrintItemsLabel";
import DeleteDialog from "../../../../../shared/components/dialogs/DeleteDialog";
import InboundPackingInformation from "./InboundPackingInformation";
import InboundTrackingNumbers from "./InboundTrackingNumbers";
import { FaChevronUp, FaChevronDown, FaExclamationTriangle } from "react-icons/lib/fa";
import TooltipAtCustomElement from "../../../../../shared/components/TooltipAtCustomElement";
import AlertPill from "../../../../../shared/components/AlertPill";
import batchActions from "../../../../../redux/batch/actions";
import { upsLogo } from "../../../../../assets/images";

const carrierLogoMap = {
  "UPSN": upsLogo,
}

const IconTooltip = () => <span className="icon icon-help-circle icon-15"></span>
const TooltipStyles = { maxWidth: "25rem", textAlign: "left", }

const {
  fulfillmentInoundPlanCreate,
  fulfillmentInoundPlanCancel,
  getFulfillmentInboundOperationStatus,
  generatePlacementOptions,
  generatePackingOptions,
  confirmPackingOption,
  fulfillmentBoxPackingSet,
  fulfillmentGeneratePlacementOptions,
  fulfillmentGeneratePlacementOptionsGet,
  fulfillmentGenerateTransportationOptions,
  fulfillmentGenerateTransportationOptionsGet,
  fulfillmentConfirmPlacementOption,
  fulfillmentConfirmDeliveryOptions,
  fulfillmentConfirmTransportationOptions,
  fulfillmentGetLabels,
  fulfillmentShipmentsListGet,
  fulfillmentInoundPlanCreateGet,
  generatePackingOptionsGet,
  fulfillmentGetItemsAvailableForInbound,
  fulfillmentGetItemsAvailableForInboundGet,
} = fulfillmentActions;

const {
	batchMetadataUpdate,
} = batchActions;

var intervalId = null;

class BatchNewFulfillment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inbound_placement_option_selected: "",
      inbound_packing_option_selected: "",
      showPackingGroupItemsModal: false,
      showPackingGroupsModal: false,
      transportation_options_selected: {},
      delivery_options_selected: {},
      delivery_window_start_date: null,
      getLabelsModalShow: false,
      printLabelsModalShow: false,
      showConfirmCancelPlanModal: false,
      goToTrackingNumbers: false,
      isCollapsed: localStorage.getItem('inboundPlanCollapsed') === 'true',
      asinsSyncPopoverOpen: false,
    };
  }

  placementFeesSum = (fees, excluding_title) => {
    var total = 0;
    fees.forEach(item => {
      if(excluding_title !== item?.target)
      total += item.value.amount;
    })
    return total;
  }

  placementOptionsGenerate = (options) => {
    return options.map(item => {
      return {
        label: `(${item.shipmentIds.length} shipment${item.shipmentIds.length > 1 ? 's' : ""}) Fees: ${digitСonversion(this.placementFeesSum(item.fees), "currency", this.props.internationalization_config.currency_code)} Discounts: ${digitСonversion(this.placementFeesSum(item.discounts || []), "currency", this.props.internationalization_config.currency_code)}`,
        value: item.placementOptionId,
      }
    })
  }

  getActiveStep = () => {
    const { batchMetadata } = this.props;
    let step = 0;
    if (batchMetadata?.inbound_plan_id) {
      step = 1;
    }
    if (!batchMetadata?.inbound_packing_informatio_set && batchMetadata?.inbound_packing_option_selected) {
      step = 2;
    }
    if(batchMetadata?.inbound_packing_option_selected
      && !batchMetadata?.inbound_transportaion_options_data
      && batchMetadata?.inbound_packing_informatio_set) {
      step = 3;
    }
    if(batchMetadata?.inbound_placement_option_selected
      && (!batchMetadata?.inbound_delivery_window_options_confirmed || !batchMetadata?.inbound_transportaion_options_option_confirmed)
    ) {
      step = 4;
    }
    if(batchMetadata?.inbound_delivery_window_options_confirmed && batchMetadata?.inbound_transportaion_options_option_confirmed) {
      step = 5;
    }
    if(batchMetadata?.inbound_plan_id && this.state.goToTrackingNumbers) {
      step = 6;
    }
    return step;
  }

  cleanTransportationConfirmationData = (data) => {
    var res = {};
    Object.keys(data).forEach(key => {
      res[key] = data[key].value;
    })
    return res;
  }

  cleanTransportationConfirmationData = (data) => {
    var res = {};
    Object.keys(data).forEach(key => {
      res[key] = data[key].value;
    })
    return res;
  }

  transportationStart = (ready_to_ship_window_start, batch_id) => {
    this.props.fulfillmentGenerateTransportationOptions({
      ready_to_ship_window_start: ready_to_ship_window_start,
      batch_id: batch_id,
    })
  }

  createInboundPlanStart = (batch_id) => {
    this.props.fulfillmentInoundPlanCreate({batch_id: batch_id})
  }

  generatePackingOptionsStart = (batch_id) => {
    this.props.generatePackingOptions({batch_id: batch_id})
  }

  generatePlacementOptionsStart = (batch_id) => {
    this.props.fulfillmentGeneratePlacementOptions({batch_id: batch_id})
  }

  componentDidUpdate = (pp) => {
    // search for transportation options
    const {
      tracking_process_id,
      create_inbound_plan_process_id,
      packing_options_process_id,
      placement_options_process_id,
    } = this.props;

    if(!pp.tracking_process_id && tracking_process_id){
      intervalId = window.setInterval(
        () => {
          this.props.fulfillmentGenerateTransportationOptionsGet({job: this.props.tracking_process_id})
        },
        5000
      );
    }
    if(pp.tracking_process_id && !tracking_process_id){
      clearInterval(intervalId);
    }

    // create inbound plan
    if(!pp.create_inbound_plan_process_id && create_inbound_plan_process_id){
      intervalId = window.setInterval(
        () => {
          this.props.fulfillmentInoundPlanCreateGet({job: this.props.create_inbound_plan_process_id})
        },
        5000
      );
    }
    if(pp.create_inbound_plan_process_id && !create_inbound_plan_process_id){
      clearInterval(intervalId);
    }

    // generate packing options
    if(!pp.packing_options_process_id && packing_options_process_id){
      intervalId = window.setInterval(
        () => {
          this.props.generatePackingOptionsGet({job: this.props.packing_options_process_id})
        },
        5000
      );
    }
    if(pp.packing_options_process_id && !packing_options_process_id){
      clearInterval(intervalId);
    }

    // generate placement options
    if(!pp.placement_options_process_id && placement_options_process_id){
      intervalId = window.setInterval(
        () => {
          this.props.fulfillmentGeneratePlacementOptionsGet({job: this.props.placement_options_process_id})
        },
        5000
      );
    }
    if(pp.placement_options_process_id && !placement_options_process_id){
      clearInterval(intervalId);
    }
  }

  componentWillUnmount = () => {
     clearInterval(intervalId);
  }

  componentDidMount = () => {
    const {
      batchMetadata,
      items_available_for_inbound_process_id
    } = this.props;
    if(!items_available_for_inbound_process_id){
      this.props.fulfillmentGetItemsAvailableForInbound({batch: batchMetadata.id})
    }
    window.setInterval(
      () => {
        this.checkFulfillmentInboundRead()
      },
      3*60*1000
    );
  }

  checkFulfillmentInboundRead = () => {
    const {
      products,
      batchMetadata,
      items_available_for_inbound_process_id,
    } = this.props;
    if(
      batchMetadata?.channel?.startsWith("AMAZON_")
        && products?.find(item => !item.valid_for_inbound)
    ){
      if(!items_available_for_inbound_process_id){
        this.props.fulfillmentGetItemsAvailableForInbound({batch: batchMetadata.id})
      } else {
        this.props.fulfillmentGetItemsAvailableForInboundGet({job: items_available_for_inbound_process_id})
      }
    }
  }

  getSelectedPlacementOption = () => {
    const { batchMetadata } = this.props;
    return batchMetadata?.inbound_placement_options_data?.placementOptions?.find(
      option => option.placementOptionId === batchMetadata.inbound_placement_option_selected
    );
  }

  getTotalAmazonFees = () => {
    const selectedOption = this.getSelectedPlacementOption();
    return this.placementFeesSum(selectedOption?.fees || [], "Shipment estimate (UPS)");
  }

  getShippingCost = () => {
    const { batchMetadata } = this.props;
    const { transportation_options_selected } = this.state;

    const firstShipmentId = Object.keys(transportation_options_selected)[0];
    const selectedOptionId = transportation_options_selected[firstShipmentId]?.value;

    return batchMetadata?.inbound_transportaion_options_data[firstShipmentId]
      ?.find(item => item.transportationOptionId === selectedOptionId)?.quote?.cost?.amount || 0;
  }

  getTotalCost = () => {
    return this.getTotalAmazonFees() + this.getShippingCost();
  }

  render() {
    const {
      batchMetadata,
      fulfillment_inbound_working,
      internationalization_config,
      items_available_for_inbound,
      userData,
      products,
    } = this.props;

    console.log("batchMetadata:", batchMetadata);

    const activeStep = this.getActiveStep();

    // block fulfillment fo employee
    if(!checkIfEmployeeAndAllowed('BOX', userData)){ return null; }

    return (
      <Card className="card-inbound-plan">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <h6 className="mb-0 mr-2">FBA Inbound Plan</h6>
            {batchMetadata?.status === "Completed" &&
            <span className="badge badge-status badge-success" style={{textTransform: "none"}}>Batch has been processed</span>
            }
          </div>
          <div className="d-flex align-items-center gap-4">
            {batchMetadata?.inbound_plan_id &&
            <div className="d-flex align-items-center gap-2">
              <small className="text-muted">ID: {batchMetadata.inbound_plan_id}</small>
              {batchMetadata?.status === "In Progress" &&
              <Button
                color="danger"
                size="sm"
                onClick={() => this.setState({
                  showConfirmCancelPlanModal: true,
                  goToTrackingNumbers: false,
                })}
                disabled={fulfillment_inbound_working}
              >
                Cancel Inbound Plan
              </Button>
              }
              {this.state.showConfirmCancelPlanModal &&
              <DeleteDialog
                isOpen={true}
                close={() => this.setState({showConfirmCancelPlanModal: false})}
                dialogTitle="Are you sure want to cancel this inbound plan?"
                confirmText="Yes, cancel inbound plan"
                cancelText="No"
                deleteItem={() => {
                  this.props.fulfillmentInoundPlanCancel({batch_id: batchMetadata.id})
                  this.setState({showConfirmCancelPlanModal: false})
                }}
              />
              }
            </div>
            }
            <div
              role="button"
              onClick={() => {
                const newCollapsedState = !this.state.isCollapsed;
                this.setState({ isCollapsed: newCollapsedState });
                localStorage.setItem('inboundPlanCollapsed', newCollapsedState);
              }}
            >
              {this.state.isCollapsed ? <FaChevronDown size={16} className="text-muted" /> : <FaChevronUp size={16} className="text-muted" />}
            </div>
          </div>
        </CardHeader>
        <Collapse isOpen={!this.state.isCollapsed} className="card-body">
          <div className="inbound-steps">
            <div className={`inbound-steps-item ${activeStep === 0 ? "active" : batchMetadata?.inbound_plan_id ? "done" : ""}`}>
              <span className="step">Create Plan</span>
            </div>
            <div className="step-arrow">/</div>

            <div className={`inbound-steps-item ${activeStep === 1 ? "active" : batchMetadata?.inbound_packing_option_selected ? "done" : ""}`}>
              <span className="step">Packing</span>
            </div>
            <div className="step-arrow">/</div>

            <div className={`inbound-steps-item ${activeStep === 2 ? "active" : batchMetadata?.inbound_packing_informatio_set ? "done" : ""}`}>
              <span className="step">Box Content</span>
            </div>
            <div className="step-arrow">/</div>

            <div className={`inbound-steps-item ${activeStep === 3 ? "active" : batchMetadata?.inbound_placement_option_selected ? "done" : ""}`}>
              <span className="step">Placement</span>
            </div>
            <div className="step-arrow">/</div>

            <div className={`inbound-steps-item ${activeStep === 4 ? "active" : batchMetadata?.inbound_delivery_window_options_confirmed ? "done" : ""}`}>
              <span className="step">Transportation</span>
            </div>
            <div className="step-arrow">/</div>

            <div className={`inbound-steps-item ${activeStep === 5 ? "active" : (batchMetadata?.inbound_plan_id && this.state.goToTrackingNumbers) ? "done" : ""}`}>
              <span className="step">Labels</span>
            </div>
            <div className="step-arrow">/</div>

            <div className={`inbound-steps-item ${activeStep === 6 ? "active" : batchMetadata?.inbound_shipments_tracking_numbers_saved ? "done" : ""}`}>
              <span className="step">Tracking</span>
            </div>
          </div>

          <hr />

          {/* Create Inbound Plan */}
          {activeStep === 0 &&
          <div className="mt-4">
            <div className="mb-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <h6 className="inbound-content-title mb-0">Create Inbound Plan</h6>
                <TooltipAtCustomElement
                  tooltipId="create-plan-tooltip"
                  tooltipText="Initiate a new shipment plan to send inventory to Amazon fulfillment centers."
                  CustomElement={IconTooltip}
                  placement="right"
                  styles={TooltipStyles}
                />
              </div>
              <p className="text-muted">
                Click "Create Inbound Plan" button to create inbound plan.
                <br />
                <span className="text-warning">Item with different expiration date per SKU is not possible to be placed in the same inbound plan.</span>
              </p>
            </div>
            <div className="d-flex align-items-center gap-4">
              <Button
                color="primary"
                onClick={() => this.createInboundPlanStart(batchMetadata.id)}
                disabled={fulfillment_inbound_working}
              >
                Create Inbound Plan
              </Button>
              <div className="d-flex align-items-center gap-2 ml-2 border px-2 py-1 rounded">
                {products?.find(item => !item.valid_for_inbound) ?
                <div className="d-flex align-items-center gap-2">
                  <span className="badge badge-status badge-warning">
                    {products?.filter(item => !item.valid_for_inbound).length}
                  </span>
                    <span className="text-warning">new ASIN{products?.filter(item => !item.valid_for_inbound).length > 0 ? "s" : null} left to sync before shipping</span>
                  <button
                    id="unsynced-asins-popover"
                    className="btn btn-link p-0"
                    onClick={() => this.setState({asinsSyncPopoverOpen: !this.state.asinsSyncPopoverOpen})}
                  >
                    View items
                  </button>
                  <UnsyncedASINsPopover
                    isOpen={this.state.asinsSyncPopoverOpen}
                    toggle={() => this.setState({asinsSyncPopoverOpen: !this.state.asinsSyncPopoverOpen})}
                    data={products?.filter(item => !item.valid_for_inbound) || []}
                  />
                  {/*
                  <Button
                    color="primary"
                    onClick={() => this.props.fulfillmentGetItemsAvailableForInbound({batch: batchMetadata.id})}
                    disabled={fulfillment_inbound_working}
                  >
                    Check item validity
                  </Button>
                  */}
                </div>
                :
                <span className="text-success font-weight-semi-bold">ASIN SYNC COMPLETE</span>
                }
              </div>
            </div>
          </div>
          }

          {/* Generate and select packing options */}
          {activeStep === 1 &&
          <div className="mt-4">
            <div className="mb-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <h6 className="inbound-content-title mb-0">Generate and Select Packing Options</h6>
                <TooltipAtCustomElement
                  tooltipId="generate-packing-options-tooltip"
                  tooltipText="Generate packing options and then select one of them"
                  CustomElement={IconTooltip}
                  placement="right"
                  styles={TooltipStyles}
                />
              </div>
              <p className="mb-4 text-muted">Click "Generate Packing Options" button to generate packing options and then select one of them.</p>
            </div>
            <div className="d-flex align-items-center gap-4">
              <Button
                color="primary"
                onClick={() => this.generatePackingOptionsStart(batchMetadata.id)}
                disabled={fulfillment_inbound_working}
              >
                Generate Packing Options
              </Button>
              {(batchMetadata?.inbound_plan_id
                && batchMetadata?.inbound_packing_options_data
                && !batchMetadata?.inbound_packing_option_selected
              ) ?
                <div className="d-flex align-items-center gap-4">
                  <div style={{minWidth: 300}}>
                    <Select
                      options={batchMetadata?.inbound_packing_options_data?.packingOptions?.map((item, idx) => {
                        return {
                          label: `Packing option ${idx + 1} [${item.packingGroups.length} packing group${item.packingGroups.length > 1 ? 's' : ""}]`,
                          value: item.packingOptionId,
                        }
                      })}
                      placeholder="Select inbound packing option"
                      onChange={(e) => this.setState({inbound_packing_option_selected: e})}
                      value={this.state.inbound_packing_option_selected}
                    />
                  </div>
                  {this.state.inbound_packing_option_selected &&
                    <Button
                      color="primary"
                      onClick={() => this.props.confirmPackingOption({
                        batch_id: batchMetadata.id,
                        packing_option_id: this.state.inbound_packing_option_selected.value,
                    })}
                      disabled={fulfillment_inbound_working}
                    >
                      Confirm Packing Option
                    </Button>
                  }
                </div>
                : fulfillment_inbound_working && (
                  <div className="d-inline-flex align-items-center gap-4">
                    <div className="skeleton" style={{width: 250, height: 35}}></div>
                    <div className="skeleton" style={{width: 100, height: 35}}></div>
                  </div>
                )
              }
            </div>
          </div>
          }

          {/* Set packing information / Box Content */}
          {activeStep === 2 &&
          <div className="mt-4">
            <div className="mb-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <h6 className="inbound-content-title mb-0">Box Content</h6>
                <TooltipAtCustomElement
                  tooltipId="box-content-tooltip"
                  tooltipText="Provide detailed information about the products being shipped such as quantities, box dimensions and weights."
                  CustomElement={IconTooltip}
                  placement="right"
                  styles={TooltipStyles}
                />
              </div>
              <p className="mb-4 text-muted">Add box and start adding items to the boxes. When you are done, click "Continue" button.</p>
            </div>
            <InboundPackingInformation
              batchMetadata={batchMetadata}
            />
            <Button
              color="primary"
              onClick={() => this.props.fulfillmentBoxPackingSet({
                batch_id: batchMetadata.id,
              })}
              disabled={fulfillment_inbound_working}
            >
              Continue
            </Button>
          </div>
          }

          {/* Generate and select placement options */}
          {activeStep === 3 &&
          <div className="mt-4">
            <div className="mb-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <h6 className="inbound-content-title mb-0">Placement Options</h6>
                <TooltipAtCustomElement
                  tooltipId="placement-options-tooltip"
                  tooltipText="Indicate your preference for how Amazon should store your inventory in their fulfillment centers."
                  CustomElement={IconTooltip}
                  placement="right"
                  styles={TooltipStyles}
                />
              </div>
              <p className="mb-4 text-muted">Click "Generate Placement Options" button to generate placement options and then select one of them.</p>
            </div>
            <div className="d-flex align-items-center gap-4">
              <Button
                color="primary"
                onClick={() => this.generatePlacementOptionsStart(batchMetadata.id)}
                disabled={fulfillment_inbound_working || batchMetadata?.inbound_placement_option_selected ? true : false}
              >
                Generate Placement Options
              </Button>
              {fulfillment_inbound_working ? (
                <div className="skeleton" style={{width: 250, height: 35}}></div>
              ) : (batchMetadata?.inbound_placement_options_data && !batchMetadata?.inbound_placement_option_selected) && (
                <div className="ml-2">
                  {/* <Label>Select inbound placement option</Label> */}
                  {/* <Select
                    options={this.placementOptionsGenerate(batchMetadata?.inbound_placement_options_data?.placementOptions)}
                    placeholder="Select inbound placement option"
                    onChange={(e) => this.props.fulfillmentConfirmPlacementOption({
                      batch_id: batchMetadata.id,
                      placement_option_id: e.value,
                    })}
                    disabled={fulfillment_inbound_working || batchMetadata?.inbound_placement_option_selected ? true : false}
                    value={this.placementOptionsGenerate(batchMetadata?.inbound_placement_options_data?.placementOptions).find(item => item.value === batchMetadata.inbound_placement_option_selected)}
                  /> */}
                  <div className="d-flex gap-2 mt-2">
                    {batchMetadata?.inbound_placement_options_data?.placementOptions
                      ?.sort((a, b) => this.placementFeesSum(a.fees) - this.placementFeesSum(b.fees))
                      ?.map((option, index, array) => {
                        const totalFees = this.placementFeesSum(option.fees);
                        const isLowestCost = index === 0;

                        return (
                          <div
                            key={index}
                            className="rounded border px-2 py-1"
                            style={{
                              backgroundColor: isLowestCost ? 'rgba(0, 148, 62, 0.1)' : 'transparent',
                              marginBottom: '0.5rem'
                            }}
                          >
                            <div>
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <span>{option.shipmentIds.length} shipment{option.shipmentIds.length > 1 ? 's' : ""}</span>
                                <Button
                                  color="primary"
                                  size="sm"
                                  disabled={batchMetadata.inbound_placement_option_selected === option.id}
                                  onClick={() => this.props.fulfillmentConfirmPlacementOption({
                                    batch_id: batchMetadata.id,
                                    placement_option_id: option.placementOptionId,
                                  })}
                                >
                                  Select
                                </Button>
                              </div>
                              {option.fees?.map((fee, i) => (
                                <div key={i} className="d-flex align-items-center justify-content-between mb-1 gap-1">
                                  <span>{fee.target}:</span>
                                  <span className="ms-2">{digitСonversion(fee.value.amount, "currency", this.props.internationalization_config.currency_code)}</span>
                                </div>
                              ))}
                              <div className="d-flex align-items-center justify-content-between gap-3 gap-1 text-warning">
                                <strong>Total: </strong>
                                <div className="text-right font-weight-bold">{digitСonversion(totalFees, "currency", this.props.internationalization_config.currency_code)}</div>
                              </div>
                            </div>
                          </div>
                        );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          }

          {/* Generate and select transportation options */}
          {activeStep === 4 &&
          <div className="mt-4">
            <div className="mb-4">
              <div className="d-flex align-items-center justify-content-between gap-4 mb-2" style={{ flexWrap: 'nowrap' }}>
                <div style={{ flexShrink: 0 }}>
                  <div className="d-flex align-items-center gap-2">
                    <h6 className="inbound-content-title mb-0">Transportation Options</h6>
                    <TooltipAtCustomElement
                      tooltipId="transportation-options-tooltip"
                      tooltipText="Select the shipping carrier and service level for your shipment."
                      CustomElement={IconTooltip}
                      placement="right"
                      styles={TooltipStyles}
                    />
                  </div>
                  {!batchMetadata?.inbound_delivery_window_options_confirmed &&
                    <p className="mb-4 text-muted">Get transportation options then choose preferred option.</p>
                  }
                </div>
                <AlertPill color="primary" icon="info" soft style={{ flexShrink: 0 }}>
                  Please note that transportation options will be generated based on your selected start date window.
                </AlertPill>
              </div>
            </div>
            {!batchMetadata?.inbound_delivery_window_options_confirmed &&
            <div className="d-inline-flex align-items-end gap-2 border p-3 rounded inbound-highlight-section">
              <div className="inbound-step-number">1</div>
              <div style={{maxWidth: 250}}>
                <Label>Choose start date window</Label>
                <DatePicker
                  selected={this.state.delivery_window_start_date}
                  onChange={date => this.setState({delivery_window_start_date: date})}
                  disabled={batchMetadata?.inbound_delivery_window_options_confirmed}
                  placeholderText="Click to select date"
                />
              </div>
              <Button
                color="primary"
                className="mr-2"
                onClick={() => this.transportationStart(
                  moment(this.state.delivery_window_start_date).format("YYYY-MM-DD"),
                  batchMetadata.id,
                )}
                disabled={
                  fulfillment_inbound_working
                    || !this.state.delivery_window_start_date
                    || batchMetadata?.inbound_delivery_window_options_confirmed
                }
              >
                Generate Transportation Options
              </Button>
            </div>
            }

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }} className="mt-4">
              {batchMetadata?.inbound_delivery_window_options_data &&
                <div className="border p-3 rounded inbound-highlight-section d-flex flex-column" style={{ height: '100%' }}>
                  <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
                    <div className="d-flex align-items-center gap-2">
                      <div className="inbound-step-number">2</div>
                      <h6 className="inbound-content-title mb-0">Product Drop Off Window</h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-end gap-2">
                      <div className="d-flex align-items-center gap-1"><Badge color="success" className="ml-2">AV</Badge> <small className="text-muted">Available</small></div>
                      <div className="d-flex align-items-center gap-1"><Badge color="warning" className="ml-2">CO</Badge> <small className="text-muted">Congested</small></div>
                    </div>
                  </div>
                  <p className="text-muted">Select delivery window option for each shipment</p>
                  {!batchMetadata?.inbound_delivery_window_options_confirmed ?
                    <React.Fragment>
                      <div className="flex-grow-1">
                        {Object.keys(batchMetadata?.inbound_delivery_window_options_data).map((shipment_id, idx) => {
                          return (
                            <div key={`transportaion-option-${idx+1}`} className="mb-3">
                              <Label>Shipment {idx + 1}</Label>
                              <Select
                                options={batchMetadata?.inbound_delivery_window_options_data[shipment_id].map(item => {
                                  return {
                                    label: (
                                      <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-2 justify-content-between">
                                        <div className="d-flex gap-2 align-items-center">
                                          <Badge color={item?.availabilityType === 'AVAILABLE' ? 'success' : 'warning'} className="ml-2">
                                            <small>{item?.availabilityType === 'AVAILABLE' ? 'AV' : 'CO'}</small>
                                          </Badge>
                                          <span className="font-bold">{momentDateTimeToLocalFormatConversion(item?.startDate, true)} - {momentDateTimeToLocalFormatConversion(item?.endDate, true)}</span>
                                        </div>
                                        <small className="text-muted text-end">Valid until: {momentDateTimeToLocalFormatConversion(item?.validUntil, true)}</small>
                                      </div>
                                    ),
                                    value: item?.deliveryWindowOptionId,
                                  }
                                })}
                                onChange={(e) => {
                                  this.setState({
                                    delivery_options_selected: {
                                      ...this.state.delivery_options_selected,
                                      ...{[shipment_id]: e}
                                    }
                                  });
                                }}
                                placeholder="Select inbound delivery window option"
                                disabled={fulfillment_inbound_working || batchMetadata?.inbound_delivery_window_options_confirmed
                                  || batchMetadata?.inbound_transportaion_options_data[shipment_id]
                                    .filter(item => !item?.shippingMode.toUpperCase().includes("LTL")).length === 0
                                }
                                value={this.state.delivery_options_selected[shipment_id]
                                  ? this.state.delivery_options_selected[shipment_id]
                                  : null
                                }
                              />
                            </div>
                          )
                        })}
                      </div>
                      <div className="mt-auto">
                        <Button
                          color="primary"
                          disabled={fulfillment_inbound_working || Object.keys(this.state.delivery_options_selected).length === 0}
                          onClick={() => this.props.fulfillmentConfirmDeliveryOptions({
                            batch_id: batchMetadata.id,
                            delivery_confirmation: this.cleanTransportationConfirmationData(this.state.delivery_options_selected),
                          })}
                        >
                          Confirm Delivery Window
                        </Button>
                      </div>
                    </React.Fragment>
                  :
                  <p className="text-success">Delivery window options confirmed.</p>
                  }
                </div>
              }
              {batchMetadata?.inbound_transportaion_options_data &&
                <div className="border p-3 rounded inbound-highlight-section">
                  <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
                    <div className="d-flex align-items-center gap-2">
                      <div className="inbound-step-number">3</div>
                      <h6 className="inbound-content-title mb-0">Transportation</h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-end gap-2">
                      <div className="d-flex align-items-center gap-1"><Badge color="primary" className="ml-2">NP</Badge> <small className="text-muted">Non-partnered</small></div>
                      <div className="d-flex align-items-center gap-1"><Badge color="warning" className="ml-2">AP</Badge> <small className="text-muted">Amazon Partnered</small></div>
                    </div>
                  </div>
                  <p className="text-muted">Select transportation option for each shipment</p>
                  {!batchMetadata?.inbound_transportaion_options_option_confirmed ?
                    <React.Fragment>
                      <div className="mb-4">
                        {Object.keys(batchMetadata?.inbound_transportaion_options_data).map((shipment_id, idx) => {
                          return (
                            <div key={`transportaion-option-${idx+1}`} className="mb-3">
                              <div className="d-flex justify-content-between gap-2">
                                <Label className="text-nowrap">Shipment {idx + 1}</Label>
                                <span className="text-muted">
                                  {batchMetadata?.inbound_transportaion_options_data[shipment_id]
                                    .filter(item => !item?.shippingMode.toUpperCase().includes("LTL"))
                                    .length > 0 ?
                                    `${batchMetadata?.inbound_transportaion_options_data[shipment_id]
                                      .filter(item => !item?.shippingMode.toUpperCase().includes("LTL"))
                                      .length} options available`
                                    :
                                    "No options available, please generate transportation options again."
                                  }
                                </span>
                              </div>
                              <Select
                                options={batchMetadata?.inbound_transportaion_options_data[shipment_id]
                                .filter(item => !item?.shippingMode.toUpperCase().includes("LTL"))
                                .sort((a,b) => (a.shippingSolution > b.shippingSolution) ? 1 : ((b.shippingSolution > a.shippingSolution) ? -1 : 0))
                                .map(item => {
                                  return {
                                    label: (
                                      <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-2 justify-content-between">
                                        <div className="d-flex gap-2 align-items-center">
                                          <Badge color={item?.shippingSolution === 'USE_YOUR_OWN_CARRIER' ? 'primary' : 'warning'} className="ml-2"><small>{item?.shippingSolution === 'USE_YOUR_OWN_CARRIER' ? 'NP' : 'AP'}</small></Badge>
                                          <div className="font-bold d-flex gap-1 flex-wrap">
                                            <span>
                                              {carrierLogoMap[item?.carrier?.alphaCode] ?
                                              <img src={carrierLogoMap[item?.carrier?.alphaCode]} alt={item?.carrier?.name} style={{width: "20px", height: "20px"}} />
                                              : `[${item?.carrier?.alphaCode}]`
                                              } {item?.carrier?.name}
                                            </span>
                                            {item?.quote?.cost?.amount && (
                                              <span className="text-success">
                                                ({digitСonversion(item?.quote?.cost?.amount || 0, "currency", this.props.internationalization_config.currency_code)})
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <small className="text-muted text-end">{item?.shippingMode}</small>
                                      </div>
                                    ),
                                    value: item?.transportationOptionId,
                                    shippingSolution: item?.shippingSolution,
                                  }
                                })}
                                onChange={(e) => {
                                  this.setState({
                                    transportation_options_selected: {
                                      ...this.state.transportation_options_selected,
                                      ...{[shipment_id]: e}
                                    }
                                  });
                                }}
                                placeholder="Select inbound transportation option"
                                disabled={fulfillment_inbound_working || batchMetadata?.inbound_transportaion_options_option_confirmed}
                                value={this.state.transportation_options_selected[shipment_id]
                                  ? this.state.transportation_options_selected[shipment_id]
                                  : null
                                }
                              />
                            </div>
                            )
                          })}
                      </div>
                      {(
                        Object.keys(this.state.transportation_options_selected).length > 0
                        && this.state.transportation_options_selected[Object.keys(this.state.transportation_options_selected)[0]].shippingSolution !== 'USE_YOUR_OWN_CARRIER'
                        ) && (
                        <div className="border rounded px-3 py-2 my-3">
                          <div className="d-flex justify-content-between mb-2">
                            <span>Total Amazon Fees:</span>
                          <span>{digitСonversion(this.getTotalAmazonFees(), "currency", internationalization_config.currency_code)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <span>Est. Shipping Cost:</span>
                          <span>{digitСonversion(this.getShippingCost(), "currency", internationalization_config.currency_code)}</span>
                        </div>
                        <div className="d-flex justify-content-between text-warning">
                          <span>Total Cost:</span>
                          <span>{digitСonversion(this.getTotalCost(), "currency", internationalization_config.currency_code)}</span>
                        </div>
                      </div>
                      )}
                      <div className="d-flex align-items-center gap-4">
                        <Button
                          color="primary"
                          className="mr-2"
                          disabled={fulfillment_inbound_working || Object.keys(this.state.transportation_options_selected).length === 0}
                          onClick={() => this.props.fulfillmentConfirmTransportationOptions({
                            batch_id: batchMetadata.id,
                            transportation_confirmation: this.cleanTransportationConfirmationData(this.state.transportation_options_selected),
                          })}
                        >
                          Confirm Transportation
                        </Button>
                        <small className="text-warning">You have 24 hours to cancel shipment after confirming transportation option.</small>
                      </div>
                    </React.Fragment>
                  :
                  <p className="text-success">Transportation options confirmed.</p>
                  }
                </div>
              }
            </div>


          </div>
          }

          {/* Get labels */}
          {activeStep === 5 &&
          <div className="mt-4">
            <div className="mb-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <h6 className="inbound-content-title mb-0">Get Labels</h6>
                <TooltipAtCustomElement
                  tooltipId="get-labels-tooltip"
                  tooltipText="Generate shipping labels from Amazon, print and attach to your shipments."
                  CustomElement={IconTooltip}
                  placement="right"
                  styles={TooltipStyles}
                />
              </div>
              <p className="mb-4 text-muted">
                Click "Get Labels" button to get labels from Amazon for each shipment.
                If you have already sent shipments, you can enter tracking numbers.
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-2">
              <div className="d-flex gap-2">
                <Button
                  color="primary"
                  disabled={fulfillment_inbound_working}
                  onClick={() => this.setState({getLabelsModalShow: true})}
                >
                  Get Labels
                </Button>
                {batchMetadata?.inbound_transportaion_options_option_confirmed_id !== "UPS" &&
                <Button
                  color="secondary"
                  onClick={() => this.setState({goToTrackingNumbers: true})}
                >
                  Enter Tracking Numbers
                </Button>
                }
              </div>
              {batchMetadata?.status === "In Progress" &&
              <Button
                color="success"
                onClick={() => this.props.batchMetadataUpdate({ status: "Completed"})}
              >
                Complete Batch
              </Button>
              }
            </div>
          </div>
          }

          {/* Enter tracking numbers */}
          {activeStep === 6 &&
          <div className="mt-4">
            <div className="mb-4">
              <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
                <div className="d-flex align-items-center gap-2">
                  <h6 className="inbound-content-title mb-0">Enter Tracking Numbers</h6>
                  <TooltipAtCustomElement
                    tooltipId="enter-tracking-numbers-tooltip"
                    tooltipText="Provide tracking numbers for your shipment to Amazon."
                    CustomElement={IconTooltip}
                    placement="right"
                    styles={TooltipStyles}
                  />
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span className="text-muted">Still need to print labels?</span>
                  <Button
                    color="secondary"
                    disabled={fulfillment_inbound_working}
                    onClick={() => this.setState({getLabelsModalShow: true})}
                  >
                    Re-print Labels
                  </Button>
                  <TooltipAtCustomElement
                    tooltipId="get-labels-tooltip"
                    tooltipText="This is the same labels from previous step. If you need to print again, click this button."
                    CustomElement={IconTooltip}
                    placement="right"
                    styles={TooltipStyles}
                  />
                </div>
              </div>
              <p className="text-muted">If you have already sent shipments, you can enter tracking numbers here.</p>
            </div>
            <InboundTrackingNumbers
              batchMetadata={batchMetadata}
            />
          </div>
          }

          {/* Packing groups modal */}
          {this.state.showPackingGroupsModal &&
          <PackGroupModal
            isOpen={this.state.showPackingGroupsModal}
            toggle={() => {this.setState({showPackingGroupsModal: !this.state.showPackingGroupsModal})}}
            batchMetadata={batchMetadata}
          />
          }

          {this.state.getLabelsModalShow &&
          <GetLabelsModal
            isOpen={this.state.getLabelsModalShow}
            toggle={() => this.setState({getLabelsModalShow: !this.state.getLabelsModalShow})}
            batchMetadata={batchMetadata}
            fulfillmentGetLabels={this.props.fulfillmentGetLabels}
            fulfillmentShipmentsListGet={this.props.fulfillmentShipmentsListGet}
            labels={this.props.labels}
            shipments_list={this.props.shipments_list}
            fulfillment_inbound_working={fulfillment_inbound_working}
          />
          }
          {this.props.fulfillment_inbound_working &&
            <div className="inbound-loading-overlay">
              <div className="d-flex flex-column align-items-center gap-3">
                <div className="d-flex align-items-center gap-2">
                  <span className="icon icon-reload icon-18 animate-spin"></span>
                  <span className="inbound-loading-text">
                    Synchronizing with Amazon
                    <span className="loading-dots ml-1"></span>
                  </span>
                </div>
                {this.props.fulfillment_inbound_working_long &&
                <div className="d-flex flex-column align-items-center">
                  <p className="text-muted text-center mb-0">
                    This process may take several minutes<br/>
                    Please do not close this window
                  </p>
                  <small className="text-muted">Started at: {moment().format('LTS')}</small>
                </div>
                }
              </div>
            </div>
          }
        </Collapse>
      </Card>
    )
  }
}

const UnsyncedASINsPopover = ({ isOpen, toggle, data }) => {
  return (
    <Popover
      isOpen={isOpen}
      toggle={toggle}
      target="unsynced-asins-popover"
      placement="auto"
    >
      <PopoverHeader>Unsynced ASINs</PopoverHeader>
      <PopoverBody className="p-3">
        <p className="text-muted">
          These ASINs are either not synced with Amazon yet or certain items are invalid for inbound. Please wait for sync to complete and remove invalid items before shipping can proceed.
        </p>
        <div className="mt-3">
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th className="align-middle">Product</th>
                <th className="align-middle">ASIN</th>
                <th className="align-middle">SKU</th>
                <th className="align-middle">Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map(product => (
                <tr key={product.asin}>
                  <td className="align-middle">
                    <div className="d-flex align-items-center">
                      <img
                        src={product.image_url
                          ? product.image_url
                          : `https://www.amazon.com/images/P/${product.asin}.01._THUMBZZZ_.jpg`
                        }
                        alt={product.name}
                        style={{width: "35px", height: "35px", objectFit: "contain", marginRight: "5px"}}
                      />
                      <span>{product.name.length > 50 ? product.name.substring(0, 50) + '...' : product.name}</span>
                    </div>
                  </td>
                  <td className="align-middle">{product.asin}</td>
                  <td className="align-middle">{product.sku}</td>
                  <td className="align-middle">
                    <span className={`badge badge-warning`}>
                      Not Available For Inbound
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </PopoverBody>
    </Popover>
  )
}

export default connect(
  state => ({
		userData: state.Auth.get('userData'),
    batchMetadata: state.Batch.get("batchMetadata"),
    fulfillment_inbound_working: state.Fulfillment.get("fulfillment_inbound_working"),
    fulfillment_inbound_working_long: state.Fulfillment.get("fulfillment_inbound_working_long"),
    tracking_process_id: state.Fulfillment.get("tracking_process_id"),
    internationalization_config: state.Auth.get("internationalization_config"),
    shipments_list: state.Fulfillment.get("shipments_list"),
    labels: state.Fulfillment.get("labels"),
    items_available_for_inbound: state.Fulfillment.get("items_available_for_inbound"),
    create_inbound_plan_process_id: state.Fulfillment.get("create_inbound_plan_process_id"),
    packing_options_process_id: state.Fulfillment.get("packing_options_process_id"),
    placement_options_process_id: state.Fulfillment.get("placement_options_process_id"),
    products: state.Batch.get("products"),
    items_available_for_inbound_process_id: state.Fulfillment.get("items_available_for_inbound_process_id"),
  }),
  {
    fulfillmentInoundPlanCreate,
    fulfillmentInoundPlanCancel,
    getFulfillmentInboundOperationStatus,
    generatePlacementOptions,
    generatePackingOptions,
    confirmPackingOption,
    fulfillmentBoxPackingSet,
    fulfillmentGeneratePlacementOptions,
    fulfillmentGeneratePlacementOptionsGet,
    fulfillmentGenerateTransportationOptions,
    fulfillmentGenerateTransportationOptionsGet,
    fulfillmentConfirmPlacementOption,
    fulfillmentConfirmDeliveryOptions,
    fulfillmentConfirmTransportationOptions,
    fulfillmentGetLabels,
    fulfillmentShipmentsListGet,
	  batchMetadataUpdate,
    fulfillmentInoundPlanCreateGet,
    generatePackingOptionsGet,
    fulfillmentGetItemsAvailableForInbound,
    fulfillmentGetItemsAvailableForInboundGet,
  }
)(BatchNewFulfillment);
