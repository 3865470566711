import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  Alert
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PriceTrackersButtonGroup from '../displays/shared/PriceTrackersButtonGroup';
import "react-datepicker/dist/react-datepicker.css";
import { digitСonversion, secureProtocolImgURL } from "../../../../helpers/utility";
import { conditionMappingNicename } from "../../../../helpers/batch/mapping_data";

class DuplicateASINWarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addQty: "1",
      conditionQty: {}
    };
  }

  listWithNewMSKU = () => {
    const {
      addItemToBatch,
      currentListingWorkflowOptions,
    } = this.props;
    this.props.setCurrentFlow("listing_creator_display");
    this.props.close();
    if (currentListingWorkflowOptions.speedMode) {
      addItemToBatch(null, false);
    }
  };

  handleChangeAddQty = e => {
    this.setState({
      addQty: e.target.value
    });
  };

  addListingQty = () => {
    const addQty = Number(this.state.addQty);
    const currentEditableListingData = this.props.currentEditableListingData;
    const { qty } = currentEditableListingData;

    if (addQty && addQty > 0) {
      let data = Object.assign({}, currentEditableListingData, {
        qty: qty + addQty
      });
      this.props.editListingItem(data);
      this.props.close();
			this.setState({
				addQty: "1",
				conditionQty: {},
			})
    }
  };

  addQty = (product) => {
    const conditionQty = this.state.conditionQty;
		if(!conditionQty[product.sku]){
			this.props.close();
		}
    const addQtyVal = Number(conditionQty[product.sku]);
    const { qty } = product;

    if (addQtyVal && addQtyVal > 0) {
			const data = Object.assign({}, product, { qty: qty + addQtyVal });
      this.props.editListingItem(data);
      this.props.close();
			this.setState({
				addQty: "1",
				conditionQty: {},
			})
		}
  };

  changeQty = (sku, val) => {
    let conditionQty = this.state.conditionQty;

		if(!conditionQty[sku]){
			conditionQty[sku] = 0;
		}
		conditionQty[sku] = conditionQty[sku] + val;
		if(conditionQty[sku] < 0){
			conditionQty[sku] = 0;
		}

    this.setState({ conditionQty: conditionQty })
  }

  changeConditionQty = (condition, value) => {
    let conditionQty = this.state.conditionQty;
    conditionQty[condition] = value;
    this.setState({
      conditionQty: conditionQty
    })
  }

  render() {
    const {
      close,
      isOpen,
      currentEditableListingData,
      currentSelectedSearchResult,
			internationalization_config,
			products
    } = this.props;
    if (currentEditableListingData) {
      var {
        imageUrl,
        asin,
        name,
        salesrank,
        condition,
				price,
      } = currentEditableListingData;
    }

		var qty_update_list = [];
		if(products && products.length){
			products.forEach(product => {
				if(product.asin === asin){
					qty_update_list.push(
						<div className="condition-item border-bottom" key={`product-item-${asin}`}>
							<span className="condition">{conditionMappingNicename[product.condition]} ({product.sku})</span>
							<span className="current-qty">
								Current qty: {product.qty}
							</span>
							<div className="qty">
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<Button onClick={() => this.changeQty(product.sku, -1)}>-</Button>
									</InputGroupAddon>
									<Input
										type="number"
										value={this.state.conditionQty[product.sku] ? this.state.conditionQty[product.sku] : 0}
										readOnly={true}
									/>
									<InputGroupAddon addonType="append">
										<Button onClick={() => this.changeQty(product.sku, 1)}>+</Button>
									</InputGroupAddon>
								</InputGroup>
							</div>
							<Button
								onClick={e => this.addQty(product)}
                disabled={!this.state.conditionQty[product.sku] || Number(this.state.conditionQty[product.sku]) === 0}
							>Add Qty</Button>
						</div>
					)
				}
			})
		}

    return (
      <React.Fragment>
        {currentEditableListingData && currentSelectedSearchResult ? (
          <Modal isOpen={isOpen} size="lg" className="duplicate-asin-modal">
            <ModalHeader>Duplicate ASIN in Batch</ModalHeader>
            <ModalBody>
              <Alert color="warning">
                There is a MSKU in the current batch matching this ASIN. You
                should update the quantity of the existing MSKU.
              </Alert>
              <Row>
                <Col xs="1" className="col-align-start">
                  <div className="media">
                    <img
                      src={secureProtocolImgURL(imageUrl)}
                      alt="Product Listing"
                    />
                  </div>
                </Col>
                <Col xs="9">
                  <h6 className="mb-3 font-weight-semi-bold">{`${name}`}</h6>
                  <div className="d-flex gap-5">
                    <div>
                      <p className="mb-1">Rank: <strong>{digitСonversion(salesrank, "rank")}</strong></p>
                      <p className="mb-0">ASIN: <strong>{asin}</strong></p>
                    </div>
                    <div>
                      <p className="mb-1">Condition: <strong>{condition}</strong></p>
                      <p className="mb-0">List Price: <strong>{digitСonversion(price, "currency", internationalization_config.currency_code)}</strong></p>
                    </div>
                    <div>
                      <PriceTrackersButtonGroup
                        ASIN={asin}
                        itemName={name}
                        amazonUrl={internationalization_config.amazon_url}
                        keepaBaseUrl={internationalization_config.keepa_url}
                        camelCamelCamelBaseUrl={internationalization_config.camelcamelcamel_url}
                      />
                    </div>
                  </div>
                </Col>
                {/* <Col xs="2">
                  <Row style={{ justifyContent: "center" }}>
                    <Input
                      style={{ maxWidth: "70px" }}
                      type="number"
                      value={this.state.addQty}
                      onChange={this.handleChangeAddQty}
                    />
                  </Row>
                  <br />
                  <Row style={{ justifyContent: "center" }}>
                    <Button color="primary" onClick={this.addListingQty}>
                      Add Qty
                    </Button>
                  </Row>
                </Col> */}
              </Row>
              <hr />
              <div className="add-per-condition mt-4">
                <p>Add quantity per condition or list with new MSKU</p>
                {qty_update_list.map(p => { return p; })}
              </div>
              <div className="text-right">
                <span className="mr-2">OR</span>
                <Button color="success" onClick={this.listWithNewMSKU}>
                  List with new MSKU <span className="icon icon-chevron-right bg-white ml-1"></span>
                </Button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={close}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

DuplicateASINWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  editListingItem: PropTypes.func.isRequired,
  currentEditableListingData: PropTypes.object,
  close: PropTypes.func.isRequired,
  setCurrentFlow: PropTypes.func.isRequired,
  addItemToBatch: PropTypes.func.isRequired,
  products: PropTypes.array,
};

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
    currentListingWorkflowOptions: state.Batch.get("currentListingWorkflowOptions"),
  }),
  {}
)(DuplicateASINWarningModal);
