import {
    Map
} from 'immutable/dist/immutable'
import actions from './actions'

const initState = new Map({
  stats: {},
  statusOfFetchChartData: {},
  statusOfFetchDashboardData: {},
  salesExpensesData: [],

  chartData: {
    units_sold: {date: null, period_type: "daily", data: null},
    asp: {date: null, period_type: "daily", data: null},
    unit_listed: {date: null, period_type: "daily", data: null},
    total_inventory: {date: null, period_type: "daily", data: null},
    turn_rate: {date: null, period_type: "daily", data: null},
    replenishable: {date: null, period_type: "daily", data: null}
  },
  period_data: {},
  period_data_loading: false,
  help_data: [],
  help_data_loading: false,
  help_data_single: [],
  help_data_single_loading: false,
  mobile_dashboard_data: {},
  mobile_dashboard_data_working: false,
})

export default function dashboardReducer(state = initState, action) {
  const chartData = Object.assign({}, state.get("chartData"))

  switch (action.type) {

    case actions.GET_MOBILE_DASHBOARD_DATA:
      return state
        .set("mobile_dashboard_data_working", true)
    case actions.GET_MOBILE_DASHBOARD_DATA_SUCCESS:
      var get_mobile_dashboard_data = {...state.get("mobile_dashboard_data")}
      get_mobile_dashboard_data = {...get_mobile_dashboard_data, ...action?.data}
      return state
        .set("mobile_dashboard_data", {...get_mobile_dashboard_data})
        .set("mobile_dashboard_data_working", false)
    case actions.GET_MOBILE_DASHBOARD_DATA_FAILED:
      return state
        .set("mobile_dashboard_data_working", false)

    case actions.SEARCH_HELP_SINGLE:
      return state
        .set("help_data_single", {})
        .set("help_data_single_loading", true)
    case actions.SEARCH_HELP_SINGLE_SUCCESS:
      return state
        .set("help_data_single", {...action.data})
        .set("help_data_single_loading", false)
    case actions.SEARCH_HELP_SINGLE_FAILED:
      return state
        .set("help_data_single", {})
        .set("help_data_single_loading", false)

    case actions.SEARCH_HELP:
      return state
        .set("help_data", [])
        .set("help_data_single", {})
        .set("help_data_loading", true)
    case actions.SEARCH_HELP_SUCCESS:
      return state
        .set("help_data", [...action.data])
        .set("help_data_loading", false)
    case actions.SEARCH_HELP_FAILED:
      return state
        .set("help_data", [])
        .set("help_data_single", {})
        .set("help_data_loading", false)

    case actions.GET_DASHBOARD_PERIOD_DATA:
      return state
        .set("period_data", {})
        .set("period_data_loading", true)
    case actions.GET_DASHBOARD_PERIOD_DATA_SUCCESS:
      return state
        .set("period_data", Object.assign({}, action.data))
        .set("period_data_loading", false)
    case actions.GET_DASHBOARD_PERIOD_DATA_FAILED:
      return state
        .set("period_data", {})
        .set("period_data_loading", false)

    case actions.FETCH_CHART_DATA_SUCCESS:
      let newChartData = Object.assign({}, state.get("chartData"))
      newChartData[action.key] = {
        ...newChartData[action.key],
        data: action.value
      };
      return state.set('chartData', newChartData);

    case actions.FETCH_CHART_DATA_STATUS:
      let statusOfFetchChartData = Object.assign({}, state.get("statusOfFetchChartData"));
      statusOfFetchChartData[action.key] = action.status;
      return state.set('statusOfFetchChartData', statusOfFetchChartData);

    case actions.FETCH_DASHBOARD_DATA_SUCCESS:
      let stats = Object.assign({}, state.get("stats"));
      stats[action.key] = action.value;
      return state.set('stats', stats);

    case actions.FETCH_DASHBOARD_DATA_STATUS:
      let statusOfFetchDashboardData = Object.assign({}, state.get("statusOfFetchDashboardData"));
      statusOfFetchDashboardData[action.key] = action.status;
      return state.set('statusOfFetchDashboardData', statusOfFetchDashboardData);

    case actions.GET_SALES_STATS:
      return state
        .set("salesExpensesData", [])

    case actions.GET_SALES_STATS_SUCCESS:
      return state
        .set("salesExpensesData", action.data.sales)

    case actions.GET_SALES_STATS_ERROR:
      return state
        .set("salesExpensesData", [])

    case actions.SET_CHART_PERIOD_TYPE:
      return state
        .set("chartData", {
          ...chartData,
          [action.chartType]: {
            ...chartData[action.chartType],
            period_type: action.period
          }
        })

    case actions.SET_CHART_END_DATE:
      return state
        .set("chartData", {
          ...chartData,
          [action.chartType]: {
            ...chartData[action.chartType],
            date: action.date
          }
        })

    default:
      return state
  }
}
