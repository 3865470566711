import { Map } from "immutable/dist/immutable";
import actions from "./actions";

const initState = new Map({
  batchList: null,
  batchListTotalCount: 0,
  batchListError: null,
  loadingList: false,
  batchDeleteError: false,
  batchDeleteLoading: false,
  batchDeleteLoaded: false,
	batchHistoryStats: {
		total_batches: 0,
		total_skus: 0,
		private_batch: 0,
		live_batch: 0,
		total_cost: 0,
		total_gross_profit: 0,
	},
	admin_feed_status: null,
	admin_feed_loading: false,
});

export default function historyReducer(state = initState, action) {
  switch (action.type) {

    case actions.BATCH_HISTORY_ITEM_UPDATE_SUCCESS:
      var update_batch_list = [...state.get("batchList")];
      update_batch_list = update_batch_list.map(item => {
        if(item.id === action?.data?.id){
          item = {...item, ...action?.data}
        }
        return item;
      })
      return state
          .set("batchList", [...update_batch_list])

		case actions.ADMIN_GET_FEED_STATUS:
			return state
				.set("admin_feed_status", null)
				.set("admin_feed_loading", true)
		case actions.ADMIN_GET_FEED_STATUS_SUCCESS:
			return state
				.set("admin_feed_status", action.data.data)
				.set("admin_feed_loading", false)
		case actions.ADMIN_GET_FEED_STATUS_FAILED:
			return state
				.set("admin_feed_status", null)
				.set("admin_feed_loading", false)

    case actions.BATCH_LIST_REQUEST:
      return state.set("batchListError", null).set("loadingList", true);
    case actions.BATCH_LIST_SUCCESS:
      return state
        .set("batchList", JSON.parse(action.batchList.results))
        .set("batchListTotalCount", action.batchList.total)
        .set("batchListError", false)
        .set("loadingList", false);
    case actions.BATCH_LIST_ERROR:
      return state.set("batchListError", true).set("loadingList", false);
    case actions.BATCH_DELETE_REQUEST:
      return state
        .set("batchDeleteError", false)
        .set("batchDeleteLoading", true)
        .set("batchDeleteLoaded", true)
        .set("deletingId", action.batchId);
    case actions.BATCH_DELETE_SUCCESS:
      return state
        .set("batchDeleteError", false)
        .set("batchDeleteLoading", false)
        .set("batchDeleteLoaded", true)
        .set("deletingId", null);
    case actions.BATCH_DELETE_ERROR:
      return state
          .set("batchDeleteError", true)
          .set("batchDeleteLoading", false)
          .set("batchDeleteLoaded", true)
          .set("deletingId", null);

	case actions.BATCH_HISTORY_STATS:
      return state
          .set("batchHistoryStats", initState.batchHistoryStats)

	case actions.BATCH_HISTORY_STATS_SUCCESS:
      return state
          .set("batchHistoryStats", action.data)

	case actions.BATCH_HISTORY_STATS_ERROR:
      return state
          .set("batchHistoryStats", initState.batchHistoryStats)

    default:
      return state;
  }
}
