import React, { Component } from 'react';
import { Badge, Button } from 'reactstrap';
import { secureProtocolImgURL } from '../../../../helpers/utility';
class RestrictedItemsPanel extends Component {
  constructor(props) {
    super(props);
    this.panelRef = React.createRef();
  }

  handleRequestApproval = (item) => {
    console.log('Request approval for:', item);
  }

  handleRemoveItem = (item) => {
    console.log('Remove item:', item);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.panelRef.current && !this.panelRef.current.contains(event.target) && this.props.isOpen) {
      this.props.toggle();
    }
  }

  render() {
    const { isOpen, toggle, items } = this.props;
    console.log(items)
    return (
      <div
        ref={this.panelRef}
        className={`restricted-items-panel ${isOpen ? 'open' : ''}`}
      >
        <div className="panel-header">
          <div className="d-flex align-items-center gap-2">
            <h4 className="panel-header-text">Restricted Items</h4>
          </div>
          <Button close onClick={toggle} />
        </div>
        <div className="panel-content">
          <div className="scroll-container">
          {items.filter(item => item?.restrictions?.restrictions).map(item => (
            <div key={item.id} className="restricted-item">
              <div className="item-details">
                <img src={secureProtocolImgURL(item.imageUrl)} alt="" className="item-image" />
                <div className="item-info">
                  <div className="item-name text-truncate">{item.name}</div>
                  <div className="item-sku">SKU: {item.sku}</div>
                  <div className="item-asin">ASIN: {item.asin}</div>
                </div>
              </div>
              <div className="item-actions">
                {item?.restrictions?.restrictions?.map((restriction, ridx) => {
                  return restriction?.reasons?.map((reason, idx) => {
                    if(reason?.reasonCode === "APPROVAL_REQUIRED"){
                      return (
                        <React.Fragment key={`reasons-id-${idx}-${ridx}`}>
                          <span className="text-danger">{reason?.message}</span>
                          {reason?.links
                            ?
                              <div className="d-flex align-items-center justify-content-between gap-2">
                                <a
                                  href={reason.links[0].resource}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn btn-success"
                                >
                                  {reason.links[0].title}
                                </a>
                                <span className="icon icon-trash bg-danger" role="button" onClick={() => this.props.openDeleteModal(item)} />
                              </div>
                            : null
                          }
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <React.Fragment key={`reasons-id-${idx}-${ridx}`}>
                          <span className="text-danger">{reason?.message}</span>
                          <div className="d-flex align-items-center justify-content-between gap-2">
                            <Badge color="danger">{reason?.reasonCode}</Badge>
                            <span className="icon icon-trash bg-danger" role="button" onClick={() => this.props.openDeleteModal(item)} />
                          </div>
                        </React.Fragment>
                      )
                    }
                  })
                })}
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    );
  }
}

export default RestrictedItemsPanel;
