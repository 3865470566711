import React, { Component } from 'react'
import Select from 'react-select'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";

const page_type_options_full = [
  {
    value: "PackageLabel_Letter_2",
    label: "Two labels per US Letter label sheet.",
    desc: "This is the only valid value for Amazon-partnered shipments in the US that use United Parcel Service (UPS) as the carrier. Supported in Canada and the US."
  },
  {
    value: "PackageLabel_Letter_4",
    label: "Four labels per US Letter label sheet.",
    desc: "This is the only valid value for non-Amazon-partnered shipments in the US. Supported in Canada and the US.",
  },
  {
    value: "PackageLabel_Letter_6",
    label: "Six labels per US Letter label sheet.",
    desc: "This is the only valid value for non-Amazon-partnered shipments in the US. Supported in Canada and the US.",
  },
  {
    value: "PackageLabel_Letter_6_CarrierLeft",
    label: "PackageLabel_Letter_6_CarrierLeft",
    desc: "",
  },
  {
    value: "PackageLabel_A4_2",
    label: "Two labels per A4 label sheet.",
    desc: "",
  },
  {
    value: "PackageLabel_A4_4",
    label: "Four labels per A4 label sheet.",
    desc: "",
  },
  {
    value: "PackageLabel_Plain_Paper",
    label: "One label per sheet of US Letter paper.",
    desc: "Only for non-Amazon-partnered shipments.",
  },
  {
    value: "PackageLabel_Plain_Paper_CarrierBottom",
    label: "PackageLabel_Plain_Paper_CarrierBottom",
    desc: "",
  },
  {
    value: "PackageLabel_Thermal",
    label: "For use of a thermal printer.",
    desc: "Supports Amazon-partnered shipments with UPS.",
  },
  {
    value: "PackageLabel_Thermal_Unified",
    label: "Unified, for use of a thermal printer.",
    desc: "Supports shipments with ATS.",
  },
  {
    value: "PackageLabel_Thermal_NonPCP",
    label: "NonPCP, for use of a thermal printer.",
    desc: "Supports non-Amazon-partnered shipments.",
  },
  {
    value: "PackageLabel_Thermal_No_Carrier_Rotation",
    label: "No carrier rotation, for use of a thermal printer.",
    desc: "Supports Amazon-partnered shipments with DHL.",
  },
];

const page_type_options = [
  {
    value: "PackageLabel_Thermal",
    label: "For use of a thermal printer.",
    desc: "Supports Amazon-partnered shipments with UPS.",
  },
  {
    value: "PackageLabel_Letter_2",
    label: "Two labels per US Letter label sheet.",
    desc: "This is the only valid value for Amazon-partnered shipments in the US that use United Parcel Service (UPS) as the carrier. Supported in Canada and the US."
  },
  {
    value: "PackageLabel_Plain_Paper",
    label: "One label per sheet of US Letter paper.",
    desc: "Only for non-Amazon-partnered shipments.",
  },
];

const label_type_options = [
  {
    value: "BARCODE_2D",
    label: "2D Barcode",
    desc: "This option is provided only for shipments where 2D Barcodes will be applied to all packages. Amazon strongly recommends using the UNIQUE option to get package labels instead of the BARCODE_2D option.",
  },
  {
    value: "UNIQUE",
    label: "Unique",
    desc: "Document data for printing unique shipping labels and carrier labels for an inbound shipment.",
  },
  {
    value: "PALLET",
    label: "Pallet",
    desc: "Document data for printing pallet labels for a Less Than Truckload/Full Truckload (LTL/FTL) inbound shipment.",
  }
]

class GetLabelsModal extends Component {
  state = {
    page_type: null,
    label_type: label_type_options[0],
    packages: "",
    labels: "",
    pallets: "",
    page_size: "1",
    page_start_index: "",
    shipment: null,
  }

  componentDidMount = () => {
    this.props.fulfillmentShipmentsListGet({batch: this.props.batchMetadata?.id});
  }

  getLabels = () => {
    const {
      page_type,
      label_type,
      packages,
      labels,
      pallets,
      page_size,
      page_start_index,
      shipment,
    } = this.state;

    const { shipment_active, batchMetadata } = this.props;

    this.props.fulfillmentGetLabels({
      batch_id: batchMetadata?.id,
      shipment_id: shipment?.value || null,
      shipment_active_id: shipment?.shipment_id || null,
      page_type: page_type?.value || null,
      //label_type: label_type?.value || null,
      //number_of_packages: packages || null,
      //package_labels_to_print: labels || null,
      //number_of_pallets: pallets || null,
      //page_size: page_size || null,
      //page_start_index: page_start_index || null,
    })
  }

  render() {
    const {
      page_type,
      label_type,
      packages,
      labels,
      pallets,
      page_size,
      page_start_index,
      shipment,
    } = this.state;

    const {
      isOpen,
      toggle,
      shipment_active_working,
      fulfillment_inbound_working,
    } = this.props

    return (
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Get Amazon Labels</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Shipment</Label>
            <Select
              options={this.props.shipments_list
                ? this.props.shipments_list.map((item, idx) => {
                    return {
                      label: `Shipment #${idx} ${item?.shipmentId}`,
                      value: item?.shipmentConfirmationId,
                      shipment_id: item?.shipmentId,
                    }
                  })
                : []
              }
              value={shipment}
              onChange={(e) => this.setState({shipment: e})}
              disabled={shipment_active_working}
            />
          </FormGroup>
          <FormGroup>
            <Label>Page Type</Label>
            <Select
              options={page_type_options}
              value={page_type}
              onChange={(e) => this.setState({page_type: e})}
              disabled={shipment_active_working}
            />
          </FormGroup>
          {/*
          <FormGroup>
            <Label>Label Type</Label>
            <Select
              options={label_type_options}
              value={label_type}
              onChange={(e) => this.setState({label_type: e})}
              disabled={shipment_active_working || true}
            />
          </FormGroup>
          <FormGroup>
            <Label>Number of Packages</Label>
            <Input
            type="number"
            value={packages}
            onChange={(e) => this.setState({packages: e.target.value})}
            disabled={shipment_active_working}
          />
          </FormGroup>
          <FormGroup>
            <Label>Packages Labels to Print</Label>
            <Input
              type="text"
              value={labels}
              onChange={(e) => this.setState({labels: e.target.value})}
              disabled={shipment_active_working}
            />
          </FormGroup>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1rem' }}>
            <FormGroup>
              <Label>Number of Pallets</Label>
              <Input
                type="number"
                value={pallets}
                onChange={(e) => this.setState({pallets: e.target.value})}
                disabled={shipment_active_working}
              />
            </FormGroup>
            <FormGroup>
              <Label>Label Amount</Label>
              <Input
                type="number"
                value={page_size}
                onChange={(e) => this.setState({page_size: e.target.value})}
                disabled={shipment_active_working}
              />
            </FormGroup>
            <FormGroup>
              <Label>Page Start Index</Label>
              <Input
                type="number"
                value={page_start_index}
                onChange={(e) => this.setState({page_start_index: e.target.value})}
                disabled={shipment_active_working}
              />
            </FormGroup>
          </div>
          */}
          <div className="d-flex justify-content-end gap-2 mt-4">
            <Button
              color="secondary"
              onClick={toggle}
            >Done</Button>
            <Button
              color="primary"
              onClick={() => this.getLabels()}
              disabled={
                shipment_active_working
                  || !shipment
                  || !page_type
                  || !label_type
                  || fulfillment_inbound_working
              }
            >Download Labels</Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default GetLabelsModal;
