import React, { Component } from "react";
//import { Route, Redirect } from "react-router";
import { Route, Redirect, Switch, Router } from "react-router-dom";
import { connect } from "react-redux";

import SignIn from "./views/pages/signin";
import Register from "./views/pages/register";
import Page404 from "./views/pages/404";
import Reset from "./views/pages/reset";
import ForgetPass from "./views/pages/forget";
import IncomeReport from "./views/pages/accounting/IncomeReport";
import ReportViewer from "./views/pages/accounting/IncomeReport/ReportViewer";
import Expenses from "./views/pages/accounting/Expenses";
import ThankYou from "./views/pages/thankyou";
import SopView from "./views/pages/sop_view";
import AmazonLWA from "./views/pages/amazon_lwa";

// dashboard pages
import Dashboard from "./Dashboard";
import BoxContents from "./views/pages/box_contents";
import Address from "./views/pages/address";
import History from "./views/pages/history";
import ListingFlow from "./views/pages/batch";
import ListEbay from "./views/pages/list_ebay";
import EbayMessages from "./views/pages/list_ebay/EbayMessages";
import ConditionNotes from "./views/pages/condition_notes";
import Settings from "./views/pages/settings";
import Notification from "./shared/components/notification";
import Membership from "./views/pages/membership";
import ProfitAnalytics from "./views/pages/profit_analytics";
import ProfitReportViewer from "./views/pages/profit_analytics/reportViewer";
import Inventory from "./views/pages/inventory";
import Admin from "./views/pages/admin";
import MainDashboard from "./views/pages/main_dashboard_v3";
//import Leaderboard from "./views/pages/leaderboard";
import Repricer from "./views/pages/repricer";
import Guide from "./views/pages/guide";
import SOP from "./views/pages/sop";
import Research from "./views/pages/research";

import FBShare from "./views/pages/fb_share";
import Onboarding_v2 from "./views/pages/onboarding_v2";
import Profitability from "./views/pages/profitability";
import AffiliateDisclosure from "./views/pages/affiliate_disclosure";
import TeamReporting from "./views/pages/team_reporting";
import FeedbackManager from "./views/pages/feedback_manager";

import EmployeeDashboard from "./views/employee/EmployeeDashboard";

// global modals
import BugReportingModal from "./shared/components/bugReportingModal";

import authActions from './redux/auth/actions';
import EbayMarketingConvert from "./views/pages/list_ebay/EbayMarketingConvert";
import VerifyPhone from "./views/pages/verify_phone";
import EBayDashboard from "./views/pages/list_ebay/EbayDashboard";
import Orders from "./views/pages/orders";
import SKUAnalysis from "./views/pages/research/SKUAnalysis";
import SalesReport from "./views/pages/sales_report";
import MobileDashboard from './views/mobile/MobileDashboard';
import MobileLayout from './views/mobile/MobileLayout';
import MobileLogin from './views/mobile';

let {adminLogin} = authActions;

const RestrictedUserRoute = ({ component: Component, isLoggedIn, user_role, ...rest }) => (
  <Route
    {...rest}
    render={props =>
			isLoggedIn ?
				['user', 'admin', 'loading'].includes(user_role) ?
					<Component {...props} />
					:
					<Redirect
						to={{
							pathname: "/",
							state: { from: props.location }
						}}
					/>
      :
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
    }
  />
);

const RestrictedEmployeeRoute = ({ component: Component, isLoggedIn, user_role, ...rest }) => (
  <Route
    {...rest}
    render={props =>
			isLoggedIn ?
				['employee', 'loading'].includes(user_role) ?
					<Component {...props} />
					:
					<Redirect
						to={{
							pathname: "/",
							state: { from: props.location }
						}}
					/>
      :
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
    }
  />
);

class PublicRoutes extends Component {

  constructor(props) {
    super(props);
    this.ref = {};
  }

  renderAdminLoginRoute(match) {
    let id = match.params.id;
    let authData = {id};
    this.props.adminLogin(authData);
    return null;
  }

  render() {
		const { history, isLoggedIn, user_role } = this.props;

    return (
      <React.Fragment>
        <Notification />
        <BugReportingModal />
        <Router history={history}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/signin"/>} />
            <Route path="/signin" render={() => (
              isLoggedIn ? (
								['user', 'admin'].includes(user_role) ?
	                <Redirect to="/dashboard/history?limit=15"/>
								: <Redirect to="/employee/dashboard"/>
              ) : (
                <SignIn />
              )
            )}/>
            <Route exact path="/thankyou" component={ ThankYou } />
            <Route exact path="/amazon/auth" component={ AmazonLWA } />
            {/*<Route exact path="/auth/amazon" render={() => <Redirect to="/dashboard/settings" /> } />*/}
            <Route exact path="/auth/amazon" component={ AmazonLWA } />
            <Route exact path="/sop/view/:id" component={ SopView } />
            <Route path="/admin_login/:id" render={(props) => this.renderAdminLoginRoute(props.match)} />
            <Route exact path="/register" component={Register} />
            <Route path="/register/:offer" component={Register} />
            <Route path="/reset_password"  component={Reset} />
            <Route path="/forget_password"  component={ForgetPass} />
            <Route path="/affiliate_disclosure"  component={AffiliateDisclosure} />
						<Route path="/fbshare/:user_id/:image_id"  component={FBShare} />
            <Route component={Page404} path="/pages/404" />
            <Route exact path="/verify_phone/:username" component={VerifyPhone} />
            <Route path="/employee">
              <Dashboard>
                <Switch>
                  <RestrictedEmployeeRoute
										exact
										path="/employee/dashboard"
                    component={EmployeeDashboard}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
									/>
                  <RestrictedEmployeeRoute
										exact
                    path="/employee/dashboard/home"
                    component={EmployeeDashboard}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
									/>
                  <RestrictedEmployeeRoute
										path="/employee/dashboard/settings"
                    component={Settings}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedEmployeeRoute
                    path="/employee/dashboard/history"
                    component={History}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedEmployeeRoute
										path="/employee/dashboard/batch/:id"
                    component={ListingFlow}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedEmployeeRoute
                    path="/employee/dashboard/inventory"
                    component={Inventory}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
									/>
                  <RestrictedEmployeeRoute
                    path="/employee/dashboard/box_contents"
                    component={BoxContents}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
									/>
                  <RestrictedEmployeeRoute
                    path="/employee/dashboard/list_ebay"
                    component={ListEbay}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
								</Switch>
							</Dashboard>
						</Route>
            <Route path="/dashboard">
              <Dashboard>
                <Switch>
                  <RestrictedUserRoute
                    path="/dashboard/admin"
                    component={Admin}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/home"
                    component={MainDashboard}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/settings"
                    component={Settings}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
									{/*
                  <RestrictedUserRoute
                    path="/dashboard/holding_area"
                    component={HoldingArea}
                    isLoggedIn={isLoggedIn}
									/>
									*/}
                  <RestrictedUserRoute
                    path="/dashboard/box_contents"
                    component={BoxContents}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/history"
                    component={History}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/batch/:id"
                    component={ListingFlow}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    exact path="/dashboard/research"
                    component={Research}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/address"
                    component={Address}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/condition_notes"
                    component={ConditionNotes}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/inventory"
                    component={Inventory}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/membership"
                    component={Membership}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    exact
                    path="/dashboard/profit_analytics"
                    component={ProfitAnalytics}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/profit_analytics/report_viewer/:uuid"
                    component={ProfitReportViewer}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    exact
                    path="/dashboard/accounting/income_report"
                    component={IncomeReport}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    exact
                    path="/dashboard/accounting/income_report/view"
                    component={ReportViewer}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/accounting/income_report/view/:uuid"
                    component={ReportViewer}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/accounting/expenses"
                    component={Expenses}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/accounting/profitability"
                    component={Profitability}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/onboarding"
                    component={Onboarding_v2}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
									/>
									{/*
                  <RestrictedUserRoute
                    path="/dashboard/leaderboard"
                    component={Leaderboard}
                    isLoggedIn={isLoggedIn}
									/>
									*/}
                  <RestrictedUserRoute
                    exact
                    path="/dashboard/repricer"
                    component={Repricer}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/repricer/:option"
                    component={Repricer}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/guide"
                    component={Guide}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/team_reporting"
                    component={TeamReporting}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/feedback_manager"
                    component={FeedbackManager}
                    isLoggedIn={isLoggedIn}
										user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/list_ebay"
                    component={ListEbay}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/ebay_messages"
                    component={EbayMessages}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/ebay"
                    component={EBayDashboard}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/start_cross_listing"
                    component={EbayMarketingConvert}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                  <RestrictedUserRoute
                    exact
                    path="/dashboard/sop"
                    component={SOP}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/sop/detail/:id"
                    component={SOP}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/sop/create"
                    component={SOP}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/sop/edit/:id"
                    component={SOP}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                  <RestrictedUserRoute
                    path="/dashboard/orders"
                    component={Orders}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                  <RestrictedUserRoute
                    exact path="/dashboard/research/sku_analysis"
                    component={SKUAnalysis}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                  <RestrictedUserRoute
                    exact path="/dashboard/sales_report"
                    component={SalesReport}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                </Switch>
              </Dashboard>
            </Route>
            <Route exact path="/mobile/login" component={MobileLogin} />
            <Route path="/mobile">
              <MobileLayout>
                <Switch>
                  <RestrictedUserRoute
                    path="/mobile/dashboard"
                    component={MobileDashboard}
                    isLoggedIn={isLoggedIn}
                    user_role={user_role}
                  />
                </Switch>
              </MobileLayout>
            </Route>
            {/* default */}
            <Route component={Page404} path="/404" />
            <Redirect from="*" to="/404" />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    isLoggedIn: state.Auth.get("access_token") !== null,
    user_role: state.Auth.get('userData') ? state.Auth.get('userData').role : 'loading',
    ...state.Auth.toJS(),
    printerDefaults: state.Settings.get("printerDefaults"),
  }),
	{
		adminLogin,
	}
)(PublicRoutes)
