import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import './style.scss';

const FullscreenLoading = ({ message, isOpen = true }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {}}
      centered={true}
      className="fullscreen-loading-modal"
    >
      <ModalBody>
        <div className="fullscreen-loading-overlay">
          <div className="fullscreen-loading-content">
            <div className="fullscreen-loading-wrapper">
              <span className="icon icon-reload icon-18 animate-spin"></span>
              <span className="loading-message">
                {message}
              </span>
              <span className="loading-dots"></span>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

FullscreenLoading.propTypes = {
  message: PropTypes.string,
  isOpen: PropTypes.bool
};

export default FullscreenLoading;
