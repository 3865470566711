import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  Row, Col, Button, Input, InputGroupAddon, InputGroup, InputGroupText,
  Modal
} from "reactstrap";
import Select from 'react-select';
import TableGenerator from "../../../../shared/components/TableGenerator";
import "./style.css";

import SideBar from "./sidebar/SideBar";
import BatchToolBoxRow from "./shared/BatchToolBoxRow";
import BatchMetricsRow from "./shared/BatchMetricsRow";
// import BatchNewFulfillmentTest from "./shared/BatchNewFulfillmentTest";
import BatchNewFulfillment from "./shared/BatchNewFulfillment";
import BatchMetadataBadges from "./shared/BatchMetadataBadges";
import ListingsTableDetail from "./shared/ListingsTableDetail";
import PropTypes from "prop-types";
import { setFocusToAmazonSearchBar } from "../../../../helpers/batch/utility";
import { digitСonversion, secureProtocolImgURL, momentDateToLocalFormatConversion } from "../../../../helpers/utility";

//import BoxIcon from "../../../../shared/components/SVGIcons/Box";
import PopoverCustomElementAndContent from "../../../../shared/components/PopoverCustomElementAndContent";
import PrintPopoverListingItem from "./shared/PrintPopoverListingItem";
import printerActions from "../../../../redux/print_service/actions";
import batchActions from "../../../../redux/batch/actions";
import SkuLabelConfirm from "../batchModals/SkuLabelConfirm";
import authActions from '../../../../redux/auth/actions';
import settingActions from '../../../../redux/settings/actions';
import { conditionMappingForPrinterLabels } from '../../../../helpers/batch/mapping_data';
import { iconTicket } from '../../../../assets/images';
import { getNextMFLabel, getPreviousMFLabel } from "../../../../helpers/print_service/utility";
import PrintMFTicketSettings from "../../settings/PrintSettings/mf_ticketing/PrintMFTicketSettings";
import TooltipAtCustomElement from "../../../../shared/components/TooltipAtCustomElement";
import RestrictedItemsPanel from "./RestrictedItemsPanel";
import ItemsApprovalResultPanel from "./ItemsApprovalResultPanel";
import FullscreenLoading from "../../../../shared/components/FullscreenLoading";
const IconTooltip = () => <span className="icon icon-help-circle" style={{ verticalAlign: 'baseline' }}></span>

const { updateUserSettings, settingsGetMFTicketing, settingsUpdateMFTicketing } = settingActions;
const { updateUserData } = authActions;
const { batchCheckRestrictedItems, batchUnavailableMove } = batchActions;
/*
This display is shown when they have not started scanning an item yet, and after the
previous item has been added successfully to the batch and you're ready to start
listing a new item.
*/
const { print } = printerActions;

const listingTableHeaders = [
    { className: "title", name: "TITLE", value: "name", sortable: false },
    { name: "RANK", value: "salesrank", sortable: true },
    { name: "PRICE", value: "price", sortable: true },
    { name: "QTY", value: "qty", sortable: false },
    { name: "CONDITION", value: "condition", sortable: false },
    { name: "ACTIONS", sortable: false }
];

const classMapping = {
	"Deleted": "badge-danger",
	"In Progress": "badge-warning",
	"Completed": "badge-success"
}

const BatchStatusBadge = ({ status }) => {
      return (
        <span className={`badge badge-status ${classMapping[status]}`}>{status}</span>
      )
}

const sortOptions = [
  {value: 'valid_for_inbound', label: 'Unsynced'},
  {value: 'name', label: 'Title'},
  {value: 'prepInstructions', label: 'Prep Instructions'},
  {value: 'price', label: 'Price'},
  {value: 'asin', label: 'ASIN'},
  {value: 'sku', label: 'SKU'},
  {value: 'salesrank', label: 'Rank'},
];

const sortOrderOptions = [
  {value: 'asc', label: <span>&uarr; ASC</span>},
  {value: 'desc', label: <span>&darr; DESC</span>}
]

class CreatedListingsDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display_products: [],
      display_sku_label_warning: false,
      sort_by: null,
      sort_order: sortOrderOptions[0],
      editBatchName: false,
      shelf: this.props.mf_ticketing.shelf,
      sequenceModal: false,
      sequence_length: '4',
      start_at: "1",
      zeros: "",
      showRestrictedPanel: false,
      showApprovalResultPanel: false,
    };
  }

  componentDidMount = () => {
    this.adjustZeros(this.state.sequence_length);
    this.props.settingsGetMFTicketing();
    setInterval(this.checkRestrictions, 15*60*1000);
  }

  checkRestrictions = () => {
    const { display_products } = this.state;
    const { batchMetadata, products } = this.props;
    if(display_products?.find(item => item?.restrictions?.restrictions)){
      this.props.batchCheckRestrictedItems({
        batch: batchMetadata?.id,
        items: products?.filter(item => item?.restrictions?.restrictions)?.map(item => {return item.id}) || [],
      });
    }
  }

	UNSAFE_componentWillMount() {
		let { products } = this.props;
		if(products){
			this.setState({display_products: products});
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		const { userData } = this.props;
		let { products } = newProps;
		this.setState({display_products: products});
		if(userData){
			if(!userData.settings.batch_sku_label_warning && products.length > 0){
				this.setState({ display_sku_label_warning: true });
			} else {
				this.setState({ display_sku_label_warning: false });
			}
		}
    if(Object.keys(newProps.mf_ticketing).length > 0 && newProps.mf_ticketing.shelf !== this.props.mf_ticketing.shelf) {
      this.setState({ ...this.state, shelf: newProps.mf_ticketing.shelf })
    }
	}

  updateMFTicketSetting = (e) => {
    const shelf = this.state.shelf;
    if(e.target.value == 'next') {
      const newShelf = getNextMFLabel(shelf);
      this.setState({...this.state, shelf: newShelf})
      this.props.settingsUpdateMFTicketing({shelf: newShelf})
    } else {
      const newShelf = getPreviousMFLabel(shelf);
      this.setState({...this.state, shelf: newShelf})
      this.props.settingsUpdateMFTicketing({shelf: newShelf})
    }
  }

	closeSkuLabelAlert = () => {
		this.setState({ display_sku_label_warning: false});
	}

  openEditModal = listing => {
    let isHoldingAreaListing = false;
    this.props.setCurrentEditableListing(listing, isHoldingAreaListing);
    this.props.updateModalDisplay("edit_listing_item");
  };

  openEditModalForClosed = listing => {
    let isHoldingAreaListing = false;
    this.props.setCurrentEditableListing(listing, isHoldingAreaListing);
    this.props.updateModalDisplay("edit_listing_item_for_closed");
  };

  openDeleteModal = listing => {
    let isHoldingAreaListing = false;
    this.props.setCurrentEditableListing(listing, isHoldingAreaListing);
    this.props.updateModalDisplay("delete_listing_item");
  };

  openEditBoxContentModal = listing => {
    let isHoldingAreaListing = false;
    this.props.setCurrentEditableListing(listing, isHoldingAreaListing);
    this.props.updateModalDisplay("edit_box_content_listing_item");
  };

  printListItem = (listing, qty) => {
    this.props.print(listing, qty);
    setFocusToAmazonSearchBar();
  };

  calculateProfit = (data) => {
    return (data?.price || 0) - (data?.buyCost || 0) - (data?.totalFeeEstimate || 0);
  }

  getMSKUFNSKU = (msku) => {
    const { batchMetadata } = this.props;
    var item = null;
    const inbound_packing_option = batchMetadata?.inbound_packing_options_data?.packingOptions?.find(item => item.packingOptionId === batchMetadata?.inbound_packing_option_selected);
    if(inbound_packing_option){
      inbound_packing_option?.packingGroups.forEach(packing_group_id => {
        item = batchMetadata?.inbound_packing_groups_data[packing_group_id].items?.find(
                item => item.msku === msku)
      })
    }
    return item?.fnsku;
  }

  listingTableOptionMapper = option => {
    const { batchMetadata } = this.props;
    const { amazon_url } = this.props.internationalConfig;
    const amazonUrl =
      "https://" +
      (amazon_url || "www.amazon.com") +
      "/gp/offer-listing/" +
      option.asin +
      "/ref=olp_fsf?ie=UTF8&f_primeEligible=true";

    return (
      <Fragment>
        <td style={{ width: '50%' }}>
          <Fragment>
            <div className="d-flex text-left">
              <div className="media flex-column align-items-center gap-2">
                <img src={secureProtocolImgURL(option.imageUrl)} alt="" />
                <div className="d-flex align-items-center gap-1">
                  {
                    !option.valid_for_inbound
                    && batchMetadata?.channel?.startsWith("AMAZON_")
                      ? <TooltipAtCustomElement
                        tooltipId={`Unsynced-${option.asin}`}
                        tooltipText="Product not valid for inbound; New Listing"
                        CustomElement={() => <span className="icon icon-16 icon-warning bg-warning d-block mx-auto"></span>}
                      />
                      : null
                  }
                  {option?.restrictions?.restrictions && (
                    <TooltipAtCustomElement
                      tooltipId={`Restricted-${option.asin}`}
                      tooltipText="Item still restricted"
                      CustomElement={() => <span className="icon icon-18 icon-restricted bg-danger d-block mx-auto"></span>}
                    />
                  )}
                </div>
              </div>
              <div style={{ fontSize: '12px', width: '100%' }}>
                <a
                  className="book-title title-link"
                  href={amazonUrl}
                  target="_blank"
                  rel="noopener noreferrer"
									style={option.fnsku && option.fnsku.startsWith('COVID19-') ? {backgroundColor: "#faaaae"} : null }
                >
                  {option.name}
                </a>
                <Row className="mt-1">
                  <Col sm="6">
                    <p className="mb-0"><strong>SKU:</strong> {!!option.sku ? option.sku : 'N/A'}</p>
                    <p className="mb-0"><strong>ASIN:</strong> {option.asin}</p>
                    <p className="mb-0">
                      <strong>PREP:</strong>
                      {option.prepInstructions ?
                        <span className="text-danger"> {option.prepInstructions}</span>
                      : null}
                    </p>
                  </Col>
                  <Col sm="3">
                    <p className="mb-0"><strong>Exp Date:</strong> {!!option.expDate ? momentDateToLocalFormatConversion(option.expDate) : 'N/A'}</p>
                    <p className="mb-0">
                      <strong>Buy Cost:</strong>&nbsp;
                      {digitСonversion(
                        option.buyCost,
                        "currency",
                        this.props.internationalConfig.currency_code
                      )}
                    </p>
                    <p className="mb-0"><strong>Supplier:</strong> {!!option.supplier ? option.supplier : 'N/A'}</p>
                  </Col>
                  <Col sm="3">
                    <p className="mb-0 attribute-indicator">
                      {/* Good rank use className="text-success", bad rank use className="text-danger" */}
                      <strong><span className={`text-${option.salesrank < 500000 ? 'success' : 'danger'} attribute-indicator-dot`}>•</span> Rank:</strong>&nbsp;<span>{digitСonversion(option.salesrank, "rank")}</span>
                    </p>
                    <p className="mb-0">
                      {/* Profit use className="text-success", bad rank use className="text-danger" */}
                      <strong><span className={`text-${this.calculateProfit(option) >= 0 ? 'success' : 'danger'} attribute-indicator-dot`}>•</span> Profit:</strong>&nbsp;<span>{digitСonversion(this.calculateProfit(option), "currency", this.props.internationalization_config?.currency_code)}</span>
                    </p>
                  </Col>
                </Row>
                <div>
                  <span className="warehouse-group">
                    {!!option.fulfillmentCenters &&
                      option.fulfillmentCenters.map((fulfillmentCenter, j) =>
                        Object.keys(
                          option.boxContents[fulfillmentCenter.ShipmentId]
                        ).map((boxNumber, k) => (
                          <span
                            key={`listing-table-warehouse-group-${option.sku}-${fulfillmentCenter.ShipmentId}-box_number-${boxNumber}`}
                            className={`badge warehouse-badge ${
                              fulfillmentCenter.DestinationFulfillmentCenterId
                            }`}
                          >{`${
                            fulfillmentCenter.DestinationFulfillmentCenterId
                          } - Box ${boxNumber}: ${
                            option.boxContents[fulfillmentCenter.ShipmentId][
                              boxNumber
                            ]
                          }`}</span>
                        ))
                      )}
                  </span>
                </div>
              </div>
            </div>
          </Fragment>
        </td>
        <td>{digitСonversion(option.salesrank, "rank")}</td>
        <td>
          {digitСonversion(
            option.price,
            "currency",
            this.props.internationalization_config.currency_code
          )}
        </td>
        <td>{option.qty}</td>
		<td>{
				conditionMappingForPrinterLabels[option.condition] ?
					conditionMappingForPrinterLabels[option.condition] :
					option.condition
			}
		</td>
        <td>
					<div className="action-controls">
						{/* 03-11-2019 show delete item for completed batches */}
						{/* {this.props.batchMetadata.status !== "Completed" ? */}
						<Fragment>
              {!this.props?.batchMetadata?.inbound_plan_id &&
                <Fragment>
                  <span className="icon icon-edit bg-primary" role="button" onClick={() => this.openEditModal(option)} title="Edit"></span>
                  <span className="icon icon-trash bg-danger" role="button" onClick={() => this.openDeleteModal(option)} title="Delete"></span>
                </Fragment>
              }
						</Fragment>
						{/*
						<Fragment>
										<EditIcon onClick={() => this.openEditModalForClosed(option)} title="Edit" />
						</Fragment>
						} */}
					{/*TODO(bojan) renove this after Amazon recover*/}
					{
						(this.props.batchMetadata.channel === 'DEFAULT') ||
						(option
							&& option.fnsku
							&& !option.fnsku.startsWith('COVID19-')) ?
					<React.Fragment>
            <PopoverCustomElementAndContent
              tooltipId={`PrintIcon${option.id}`}
              TooltipContent={(props) => (
                <PrintPopoverListingItem
                  option={option}
                  printListItem={this.printListItem}
                  togglePopover={props.togglePopover}
                />
              )}
              customElement={
                <span className="icon icon-print bg-primary" role="button"></span>
              }
						/>
            {/* <BoxIcon
              onClick={() => this.openEditBoxContentModal(option)}
              title="Edit Box"
            /> */}
					</React.Fragment>	:
					<React.Fragment>
            {
              (this.props?.batchMetadata?.inbound_plan_id
                && this.props?.batchMetadata?.inbound_packing_option_selected
              ) &&
            <span
              className="icon icon-print"
              role="button"
              onClick={() => this.printListItem({...option, ...{fnsku: this.getMSKUFNSKU(option.sku)}}, 1)}
            />
            }
						{" "}
						{/* <BoxIcon onClick={() => null} disabled={true} /> */}
					</React.Fragment>
					}
          </div>
        </td>
      </Fragment>
    );
  };

  searchBatch = (search) => {
	if(!search){
		this.setState({display_products: this.props.products});
		return;
	}
	  let products_to_display = [];
	  search = search.toUpperCase();
	  this.props.products.forEach((row) => {
		let key = [row.name, row.suplier, row.sku, row.asin, row.price.toString()]
		key = key.join(' ').toUpperCase();
		if(key.includes(search)){
			products_to_display.push(row);
		}
	  }
	);
	this.setState({display_products: products_to_display});
  }

  sortChanged = (type, selectedOption) => {
    let products = this.state.display_products.map(item => Object.assign({}, item));

    if(type === 'sort_by' && this.state.sort_order !== null) {
      products.sort((a,b) => this.sortProducts(a,b, selectedOption.value, this.state.sort_order.value));
    } else if (type === 'sort_order' && this.state.sort_by !== null) {
      products.sort((a,b) => this.sortProducts(a,b, this.state.sort_by.value, selectedOption.value));
    }

    this.setState({
      [type]: selectedOption,
      display_products: products
    });
  }

  sortProducts = (a, b, by, order) => {
    let reverse = order === 'desc' ? -1 : 1;

    if (by === 'valid_for_inbound') {
      return (a[by] === b[by]) ? 0 : (a[by] ? -1 : 1) * reverse;
    }

    if(a[by] < b[by]) return -1 * reverse;
    if(a[by] > b[by]) return 1 * reverse;
    return 0;
  }

  handleChangeBatchName = () => {
    this.props.batchMetadataUpdate({
      batchName: this.state.newBatchName,
      batch_name: this.state.newBatchName,
    });
    this.setState({ editBatchName: false });
  }

  toggleSequenceModal = () => {
    this.setState({
      ...this.state,
      sequenceModal: !this.state.sequenceModal
    })
    this.props.settingsGetMFTicketing();
  }

  adjustZeros = (sequence_length) => {
		const start_at_length = this.state.start_at.length;
		const remaining_slot = sequence_length-start_at_length;
		let zeros = "";
		for(let i=1; i<=remaining_slot; i++) {
			zeros += "0";
		}

		this.setState({
			zeros: zeros
		});
	};

	onSelectChange = (name, value) => {
		this.setState({
			[name]: value
		});

		// adjust zeros and start_at
		if(name === "sequence_length") {
			let start_at = this.state.start_at;
			let zeros = this.state.zeros;

			if(value <= (start_at.length + zeros.length)) {
				let removed_zeros_length = value - start_at.length;

				zeros = zeros.substr(0, removed_zeros_length);

				if(removed_zeros_length < 0) {
					start_at = start_at.substr(0, value);
				}

				this.setState({
					start_at: start_at,
					zeros: zeros
				})
			} else {
				this.adjustZeros(value);
			}
		}
	};

	onStartChange = (value) => {
		let zeros_length = this.state.sequence_length - value.length;
		let zeros = "";

		for(let i=0; i<zeros_length; i++) {
			zeros += "0";
		}

		this.setState({
			start_at: value,
			zeros: zeros
		})
	};

  toggleRestrictedPanel = () => {
    if(!this.state.showRestrictedPanel){this.checkRestrictions()}
    this.setState({
      showRestrictedPanel: !this.state.showRestrictedPanel,
      showApprovalResultPanel: false
    });
  }

  toggleApprovalResultPanel = () => {
    this.setState({
      showApprovalResultPanel: !this.state.showApprovalResultPanel,
      showRestrictedPanel: false
    });
  }

  isRowHighlighted = (option) => {
    const { batchMetadata } = this.props;
    return (
      !option.valid_for_inbound
        || option?.restrictions?.restrictions
    ) && batchMetadata?.channel?.startsWith("AMAZON_");
  }

  render() {
  let {
    handleSearchSubmit,
    handleSearchChange,
    displayCustomSKUTemplateModal,
    currentModal,
    batchMetadata,
    batchListingDefaults,
    updateListingDefaultsData,
    existingShipments,
    sidebarTabId,
    setSidebarTabId,
    closeModal,
    createNewSupplier,
    suppliers,
	scouts,
    internationalConfig,
    batchLoaded,
    setAllBatchListingRowsExpanded,
    allBatchListingRowsExpanded,
  } = this.props;

  return (
    <React.Fragment>
      <div className="batch-core">
        <BatchToolBoxRow
          handleSearchSubmit={handleSearchSubmit}
          handleSearchChange={handleSearchChange}
        />
        <div className="batch-header">
			<Row className="align-items-center">
				<Col lg={6}>
          <div className="d-flex align-items-center">
            {this.state.editBatchName && batchMetadata.status === 'In Progress' ?
            <InputGroup style={{ width: "20rem" }} className="mr-4">
              <Input onChange={e => this.setState({'newBatchName':  e.target.value.trim()})} defaultValue={batchMetadata.batchName} />
              <InputGroupAddon addonType="append">
                <Button disabled={!this.state.newBatchName} onClick={() => this.handleChangeBatchName()} color="success"><span className="icon icon-12 icon-check bg-white"></span></Button>
                <Button onClick={() => this.setState({ editBatchName: false })}><span className="icon icon-12 icon-close"></span></Button>
              </InputGroupAddon>
            </InputGroup>

            :
            <div className="d-flex align-items-center gap-2 mr-3">
              <h2 className="batch-name">
                {batchMetadata.batchName}
              </h2>
              {batchMetadata.status === 'In Progress' ?
                <Button
                  color="link"
                  className="text-muted"
                  onClick={() => this.setState({ editBatchName: true })}
                >
                  <span className="icon icon-edit" style={{ verticalAlign: 'baseline' }}></span>
                </Button>
                : null
              }
            </div>
            }
	          {!!batchMetadata.status && <span><BatchStatusBadge status={batchMetadata.status} /></span> }
            &nbsp;
            <TooltipAtCustomElement
              tooltipId="BatchInfo"
              tooltipText="This is your batch name and also a breadcrumb
              trail beneath it to remind you of the batch
              settings you set for this batch"
              CustomElement={IconTooltip}
              styles={{ maxWidth: "20rem" }}
            />
          </div>
			  </Col>
			  <Col lg={6}>
				  <BatchMetadataBadges batchMetadata={batchMetadata} />
			  </Col>
		  </Row>
        </div>

        <div className="medium-top-margin medium-bottom-margin">
          {(
            batchLoaded
              && batchMetadata?.channel?.startsWith("AMAZON_")
              || (batchMetadata?.status === "Completed" && batchMetadata?.inbound_plan_id)
              || (batchMetadata?.status === "Deleted" && batchMetadata?.inbound_plan_id)
          ) &&
          <Fragment>
            <BatchNewFulfillment
              batchLoaded={batchLoaded}
            />
          </Fragment>
          }
          <BatchMetricsRow
            existingShipments={existingShipments}
            batchLoaded={batchLoaded}
            searchBatch={this.searchBatch}
          />
        </div>

        {batchLoaded &&
        <Row className="mb-3">
          <Col lg="6">
            <div className="d-flex align-items-center justify-content-start">
              <label style={{ flex: '0 0 auto'}} className="m-0">Sort by:</label>
              <div style={{ minWidth: "11rem" }} className="ml-3">
                <Select
                  options={sortOptions}
                  className="Select-sm"
                  value={this.state.sort_by}
                  onChange={(e) => this.sortChanged("sort_by", e)}
                  clearable={false}
                  isSearchable={false}
                />
              </div>
              <div style={{ minWidth: "6rem" }} className="ml-1">
                <Select
                  options={sortOrderOptions}
                  className="Select-sm"
                  value={this.state.sort_order}
                  onChange={(e) => this.sortChanged("sort_order", e)}
                  clearable={false}
                  isSearchable={false}
                />
              </div>
              <div className="sequence-reminder ml-3" style={{  }}>
                <InputGroup size="sm">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><img src={iconTicket} alt="" /></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    readOnly
                    className="readonly-white-bg"
                    value={this.state.shelf}
                    onClick={this.toggleSequenceModal}
                    title="Click to change MF ticket label settings"
                  />
                  <InputGroupAddon addonType="append">
                    <Button onClick={(e) => this.updateMFTicketSetting(e)} value="previous">-</Button>
                    <Button onClick={(e) => this.updateMFTicketSetting(e)} value="next">+</Button>
                  </InputGroupAddon>
                </InputGroup>
                <Modal isOpen={this.state.sequenceModal} toggle={this.toggleSequenceModal} size="sm">
                  <PrintMFTicketSettings
                    onStartChange={this.onStartChange}
                    onSelectChange={this.onSelectChange}
                  />
                </Modal>
              </div>
              {this.state.display_products?.find(item => item?.restrictions?.restrictions) &&
                <React.Fragment>
                  <Button
                    color="warning"
                    size="sm"
                    className="ml-3"
                    onClick={this.toggleRestrictedPanel}
                  >
                    <span className="icon icon-warning bg-white"></span> View Restricted Items
                  </Button>
                  <RestrictedItemsPanel
                    isOpen={this.state.showRestrictedPanel}
                    toggle={this.toggleRestrictedPanel}
                    items={this.props.products}
                    openDeleteModal={this.openDeleteModal}
                  />
                </React.Fragment>
              }
              {(
                this.state.display_products?.find(item => !item?.valid_for_inbound)
                  || this.state.display_products?.find(item => item?.restrictions?.restrictions)
                ) &&
                <Button
                  color="success"
                  size="sm"
                  className="ml-3"
                  onClick={() => this.props.batchUnavailableMove({batch: batchMetadata?.id})}
                >
                  <span className="icon icon-16 icon-folder-move bg-white"></span> Move Unavailable
                </Button>
              }
            </div>
          </Col>
        </Row>
        }

        <TableGenerator
          rootClassName="table batch-table"
          headerTitles={listingTableHeaders}
          optionMapper={this.listingTableOptionMapper}
          expandableMapper={ListingsTableDetail}
          expandableAdditionalProps={{ internationalConfig }}
          isExpandable
          isLoading={!batchLoaded}
          options={this.state.display_products}
          defaultLoadingText={"Pulling your batch up... hang tight for a minute ️🏄‍"}
          defaultText={
            "Make sure all your batch settings are correct before scanning your first product and starting your batch."
          }
          setAllBatchListingRowsExpanded={setAllBatchListingRowsExpanded}
          allBatchListingRowsExpanded={allBatchListingRowsExpanded}
					defaultLimit={50}
          isRowHighlighted={this.isRowHighlighted}
        />
      </div>

      <div className="batch-sidebar">
        <SideBar
          batchListingDefaults={batchListingDefaults}
          updateListingDefaultsData={updateListingDefaultsData}
          displayCustomSKUTemplateModal={displayCustomSKUTemplateModal}
          sidebarTabId={sidebarTabId}
          setSidebarTabId={setSidebarTabId}
          createNewSupplier={createNewSupplier}
          suppliers={suppliers}
          scouts={scouts}
          currentModal={currentModal}
          closeModal={closeModal}
        />
      </div>
	  <SkuLabelConfirm
		toggle={this.closeSkuLabelAlert}
		isOpen={this.state.display_sku_label_warning}
		updateUserData={this.props.updateUserData}
		userData={this.props.userData}
		updateUserSettings={this.props.updateUserSettings}
	  />
	  <FullscreenLoading
		  message="Moving unavailable items to a new batch"
		  isOpen={this.props.moving_items_unavailable}
	  />
	  <FullscreenLoading
		  message="Adding replenished item to batch"
		  isOpen={this.props.add_to_batch_replenish_working}
	  />
    </React.Fragment>
  );
}
};

CreatedListingsDisplay.propTypes = {
  internationalConfig: PropTypes.object.isRequired,
  handleSearchSubmit: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  batchMetadata: PropTypes.object.isRequired,
  batchListingDefaults: PropTypes.object.isRequired,
  updateListingDefaultsData: PropTypes.func.isRequired,
  existingShipments: PropTypes.array,
  updateModalDisplay: PropTypes.func.isRequired,
  setCurrentEditableListing: PropTypes.func.isRequired,
  sidebarTabId: PropTypes.string.isRequired,
  setSidebarTabId: PropTypes.func.isRequired,
  createNewSupplier: PropTypes.func.isRequired,
  suppliers: PropTypes.array.isRequired,
  scouts: PropTypes.array.isRequired,
  batchLoaded: PropTypes.bool.isRequired,
  setAllBatchListingRowsExpanded: PropTypes.func,
  allBatchListingRowsExpanded: PropTypes.bool,
  batchMetadataUpdate: PropTypes.func.isRequired,
  mf_ticketing: PropTypes.object.isRequired
};

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
    userData: state.Auth.get("userData"),
    mf_ticketing: state.Settings.get("mf_ticketing"),
    moving_items_unavailable: state.Batch.get("moving_items_unavailable"),
    add_to_batch_replenish_working: state.Batch.get("add_to_batch_replenish_working"),
  }),
  {
	  print,
	  updateUserData,
	  updateUserSettings,
    settingsGetMFTicketing,
    settingsUpdateMFTicketing,
    batchCheckRestrictedItems,
    batchUnavailableMove,
  }
)(CreatedListingsDisplay);
