import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
  Container,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { logoColorMd } from '../../assets/images';
import authAction from '../../redux/auth/actions'

const { login } = authAction

class MobileLogin extends Component {
  state = {
    username: '',
    password: '',
    showPassword: false
  };

  handleInputChange = (inputType, e) => {
      let change = {}
      change[inputType] = e.target.value
      this.setState(change)
  }

  toggleShowPassword = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let authData = {
      username_or_email: this.state.username,
      password: this.state.password,
      is_mobile: true,
    }

    this.props.login(authData)
  }

  render() {
    const { username, password, showPassword } = this.state;

    return (
      <div className="mobile-container-fullwidth">
        <Container fluid>
          <Card style={{ width: 330, maxWidth: 400 }}>
            <CardBody>
              <div className="text-center mb-4">
                <img src={logoColorMd} alt="logo" height={25} style={{ objectFit: 'contain' }} />
              </div>

              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => this.handleInputChange('username', e)}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => this.handleInputChange('password', e)}
                      required
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        style={{ cursor: 'pointer', backgroundColor: 'transparent', borderLeft: 'none' }}
                        onClick={this.toggleShowPassword}
                      >
                        {showPassword ? 'HIDE' : 'SHOW'}
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>

                <Button
                  color="success"
                  block
                  size="lg"
                  type="submit"
                  className="mt-4"
                >
                  Login
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

export default connect(
    state => ({
        ...state.Auth.toJS()
    }),
    { login }
)(MobileLogin)
