import React, { Component } from 'react';
import {
  ResponsiveContainer, XAxis, YAxis, CartesianGrid,
  Tooltip, BarChart, Bar
} from 'recharts';
import { momentDateToISOFormatConversioniWithFormat } from "../../../helpers/utility";
import chart_config from '../../../views/pages/main_dashboard_v3/chart_config';

class ItemsListedChart extends Component {
  dateFormat = (val) => {
    return momentDateToISOFormatConversioniWithFormat(val, "MM/DD/YY");
  }

  render() {
    return (
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={this.props.data} margin={{ top: 10, right: 10, left: -30, bottom: 0 }}>
          <CartesianGrid vertical={false} stroke={chart_config.cartesianStrokeColor} />
          <XAxis
            dataKey="date"
            tickFormatter={this.dateFormat}
            fontSize={chart_config.axisFontSize}
            tick={{ fill: chart_config.axisFontColor }}
          />
          <YAxis
            fontSize={chart_config.axisFontSize}
            tick={{ fill: chart_config.axisFontColor }}
          />
          <Tooltip
            labelFormatter={this.dateFormat}
            cursor={{ fill: chart_config.cartesianStrokeColor }}
          />
          <Bar
            dataKey="items"
            name="Items Listed"
            fill={chart_config.greenColor}
            radius={chart_config.barRadius}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default ItemsListedChart;
