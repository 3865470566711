import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { secureProtocolImgURL } from '../../../../helpers/utility';

class ItemsApprovalResultPanel extends Component {
  handleRemoveItem = (item) => {
    console.log('Remove item:', item);
  }

  getStatusBadge = (status) => {
    const badges = {
      'under_review': 'warning',
      'approved': 'success', 
      'declined': 'danger'
    };
    return badges[status] || 'secondary';
  }

  render() {
    const { isOpen, toggle, items } = this.props;
    return (
      <div className={`restricted-items-panel ${isOpen ? 'open' : ''}`}>
        <div className="panel-header">
          <div className="d-flex align-items-center gap-2">
            <h4 className="panel-header-text">Approval Results</h4>
            <Button color="success" size="sm" onClick={() => {}}>Re-validate all approved items</Button>
          </div>
          <Button close onClick={toggle} />
        </div>
        <div className="panel-content scroll-container">
          {items.map(item => (
            <div key={item.id} className="restricted-item">
              <div className="item-details mb-0">
                <img src={secureProtocolImgURL(item.imageUrl)} alt="" className="item-image" />
                <div className="item-info">
                  <div className="item-name text-truncate">{item.name}</div>
                  <div className="item-sku">SKU: {item.sku}</div>
                  <div className="item-asin">ASIN: {item.asin}</div>
                  <div className="mt-2 d-flex align-items-center gap-2">
                    <span className={`badge badge-${this.getStatusBadge(item.status)}`}>
                      {item.status === 'under_review' ? 'Under Review' :
                       item.status === 'approved' ? 'Approved' : 'Declined'}
                    </span>
                    {item.status === 'declined' && (
                      <Button 
                        color="danger"
                        size="sm" 
                        onClick={() => this.handleRemoveItem(item)}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ItemsApprovalResultPanel;